import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as serviceActions from "../../store/servicesManagment/actions"
import TableContainer from "components/Common/TableContainer"
import { Name } from "./AccCol"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap"
import moment from "moment";

const ServiceList = ({ services, handleEditService, handleRemoveService }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [service, setService] = useState(null)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState("")

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleImageModal = () => {
    setIsImageModalOpen(!isImageModalOpen)
  }

  const moreDetails = data => {
    setService(data)
    toggleModal()
  }

  const openImageModal = url => {
    setImageUrl(url)
    toggleImageModal()
  }

  const columns = useMemo(
    () => [
      {
        Header: "مقدم الخدمة",
        accessor: "full_name", // This might need to be adjusted based on how you want to use it
        disableFilters: true,

        Cell: cellProps => cellProps.row.original.serviceProviders.full_name,
      },
      {
        Header: "  اسم الخدمة",
        accessor: "name",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />,
      },
      {
        Header: " قسم الخدمة",
        accessor: "service_category",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />,
      },
      {

        accessor: "more",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button onClick={() => moreDetails(row.original)}>
            عرض التفاصيل
          </Button>
        ),
      },
      {

        accessor: "delete",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            color="danger"
            onClick={() => handleRemoveService(row.original)}
          >
            حذف

          </Button>
        ),
      },
      {

        accessor: "edit",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditService(row.original)}
          >
            تعديل
          </Button>
        ),
      },

    ],
    []
  )

  return (

    <>

      <TableContainer
        columns={columns}
        data={services || []}
        isGlobalFilter={false}
        customPageSize={5}
        customPageSizeOptions={true}
      />
      <DiscriptionModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        service={service}
        openImageModal={openImageModal}
      />
      <ImageModal
        isOpen={isImageModalOpen}
        toggleModal={toggleImageModal}
        imageUrl={imageUrl}
      />
    </>


  )
}

ServiceList.propTypes = {
  services: PropTypes.array.isRequired,
  handleEditService: PropTypes.func.isRequired,
  handleRemoveService: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  services: state.service.services,
})

const DiscriptionModal = ({ isOpen, toggleModal, service, openImageModal }) => {
  if (!service) {
    return null; // Return null if no order data is available
  }
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>تفاصيل الخدمة</ModalHeader>
      <ModalBody>
        <p className="mb-2">
          <strong>تاريخ التسجيل:</strong>{" "}
          <span className="text-primary">
            {moment(service.booking_execute_date).format("DD MMMM, YYYY")}
          </span>
        </p>
        <p className="mb-2 ">
          <strong className="m-3"> قسم الخدمة :</strong>
          <span>
            {service.service_category}
          </span>
        </p>

        <hr />
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              <tr>
                <td>
                  <strong>اسم الخدمة :</strong>
                </td>
                <td> {service.name}</td>
              </tr>


              <tr>
                <td>
                  <strong>  السعر الخدمة:</strong> </td>
                <td>{service.service_price} </td>

              </tr>

              <tr>
                <td>
                  <strong>  سعر الخدمة في المنزل :</strong>
                </td>
                <td>{service.at_home_service_cost}</td>
              </tr>
              <tr>
                <td>
                  <strong>  نوع الخدمة :</strong>
                </td>
                <td>{service.service_type}</td>
              </tr>
              <tr>
                <td>
                  <strong>   الوصف:</strong>
                </td>
                <td>{service.description}</td>
              </tr>
              <tr>
                <td>
                  <strong>متوسط وقت التنفيذ:</strong>
                </td>
                <td>
                  {service.avg_execute_time >= 60
                    ? `${(service.avg_execute_time / 60).toFixed(2)} ساعة`
                    : `${service.avg_execute_time} دقيقة`}
                </td>
              </tr>


              <tr>
                <td >
                  <strong>  مقدم الخدمة:</strong>
                </td>
                <td style={{ direction: "ltr" }}> {service.serviceProviders?.full_name ?? ''}</td>
              </tr>

              <tr>
                <td >
                  <strong>   رقم الهاتف مقدم الخدمة :</strong>
                </td>
                <td style={{ direction: "ltr" }}> {service.serviceProviders?.mobile ?? ''}</td>
              </tr>


              <tr>
                <td>
                  <strong>  صورة الخدمة:</strong>
                </td>
                <td>
                  {service.img_url ? (
                    <img
                      src={service.img_url}
                      alt={`Image`}
                      style={{ width: "50px", height: "50px" }}
                      onClick={() => openImageModal(service.img_url)}
                    />
                  ) : (
                    <img
                      src={"./images/noimg.jpg"}
                      alt={`Image`}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() => openImageModal("./images/noimg.jpg")}
                    />
                  )}
                </td>
              </tr>

            </tbody>

          </Table>

        </div>

      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleModal}>
          إغلاق
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const ImageModal = ({ isOpen, toggleModal, imageUrl }) => (
  <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
    <ModalHeader toggle={toggleModal}>صورة الخدمة</ModalHeader>
    <ModalBody>
      <img src={imageUrl} alt="Service" style={{ width: "100%" }} />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        إغلاق
      </Button>
    </ModalFooter>
  </Modal>
)

export default connect(mapStateToProps, serviceActions)(ServiceList)