import * as actionTypes from './actionTypes';


export const loginUser = (user, history) => ({
  type: actionTypes.LOGIN_USER,
  payload: { user, history },
})

export const loginSuccess = user => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginFailure = errorMessage => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: errorMessage,
})

export const logoutUser = history => ({
  type: actionTypes.LOGOUT_USER,
  payload: { history },
})

export const logoutUserSuccess = () => ({
  type: actionTypes.LOGOUT_USER_SUCCESS,
})

export const apiError = error => ({
  type: actionTypes.API_ERROR,
  payload: error,
})

export const fetchUserInfo = userId => ({
  type: actionTypes.FETCH_USER_INFO,
  payload: userId,
})

export const storeUserInfo = userInfo => ({
  type: actionTypes.STORE_USER_INFO,
  payload: userInfo,
})

export const resetUserInfo = () => {
  return {
    type: actionTypes.RESET_USER_INFO,
  }
}




export const updateUserInfoRequest = (userInfo) => ({
  type: actionTypes.UPDATE_USER_INFO_REQUEST,
  payload: userInfo,
});

export const updateUserInfoSuccess = (updatedUserInfo) => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
  payload: updatedUserInfo,
});

export const updateUserInfoFailure = (error) => ({
  type: actionTypes.UPDATE_USER_INFO_FAILURE,
  payload: error,
});



export const changePasswordRequest = (currentPassword, newPassword) => ({
  type: actionTypes.CHANGE_PASSWORD_REQUEST,
  payload: { currentPassword, newPassword },
});

export const changeEmailRequest = (newEmail) => ({
  type: actionTypes.CHANGE_EMAIL_REQUEST,
  payload: { newEmail },
});

// Action creators
export const changeEmailFailure = (error) => ({
  type:  actionTypes.CHANGE_EMAIL_FAILURE,
  payload: error, // You can pass any error information here
});

export const changePasswordFailure = (error) => ({
  type: actionTypes.CHANGE_PASSWORD_FAILURE,
  payload: error, // You can pass any error information here
});

export const clearEmailChangeError = () => ({
  type: actionTypes.CLEAR_EMAIL_CHANGE_ERROR,
});

export const clearPasswordChangeError = () => ({
  type: actionTypes.CLEAR_PASSWORD_CHANGE_ERROR,
});

export const clearEmailChangeSuccess = () => ({
  type: actionTypes.CLEAR_EMAIL_CHANGE_SUCCESS,
});

export const clearPasswordChangeSuccess = () => ({
  type: actionTypes.CLEAR_PASSWORD_CHANGE_SUCCESS,
});



export const saveUserToken = (token) => ({
  type: actionTypes.SAVE_USER_TOKEN,
  payload: token,
});