import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import { 
  fetchSettingsRequest, 
  updateSettingsRequest, 
  fetchDataRequest 
} from "../../store/actions";

import CommissionSettings from "./CommissionSettings";
import AboutUsSettings from "./AboutUsSettings";
import Bordcast from "./Bordcast";
import Admins from "./Admins";
import BannerUpload from "./BannerUpload";
import NewSuperAdminForm from "./NewSuperAdminForm";

const GeneralSettings = () => {
  const data = useSelector(state => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettingsRequest());
    dispatch(fetchDataRequest());
  }, [dispatch]);

  const handleSaveSettings = () => {
    if (data) {
      dispatch(updateSettingsRequest(data));
    }
  };

  return (
    <div className="page-content">
      <Container fluid={false}>
        <Row>
          <CommissionSettings commission={data} />
          <AboutUsSettings about={data} />
        </Row>
        <Row>
          {/* <Bordcast /> */}
        </Row>
        <Row>
          <NewSuperAdminForm />
          <Admins />
        </Row>
        <Row className="justify-content-md-center"> 
          {/* Additional content can be added here if needed */}
          
        </Row>
        <BannerUpload />
        {/* <MaintenanceMode data={data} /> */}
      </Container>
    </div>
  );
};

export default GeneralSettings;
