import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch } from "react-redux";
import * as action from "../../store/actions";
import { toast } from "react-toastify";

const EditFormModal = ({ isOpen, toggle, editData }) => {
  if (!editData) {
    return null; // Return null if no order data is available
  }
  const [editedUser, setEditedUser] = useState(editData);
  const [isFormChanged, setIsFormChanged] = useState(false); // Track form changes
  const [errors, setErrors] = useState({}); // Track form errors
  const dispatch = useDispatch();

  useEffect(() => {
    setEditedUser(editData);
    setIsFormChanged(false);
    setErrors({});
  }, [editData]);
  const errorMessages = {
    full_name: "اسم المستخدم مطلوب",
    email: "البريد الإلكتروني مطلوب",
    mobile: "رقم الهاتف مطلوب ويجب أن يحتوي على أرقام فقط",
    bank_account: "حساب البنكي مطلوب ويجب أن يحتوي على أرقام فقط",
    description: "الوصف مطلوب",
    salon_name: "اسم الصالون مطلوب",
    password: "كلمة المرور مطلوبة",
    account_name: "اسم البنك مطلوب",
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" ) {
      if (!/^\d*$/.test(value)) {
        setErrors({ ...errors, [name]: "هذا الحقل يجب أن يحتوي على أرقام فقط" });
        return;
      }
    }
    setEditedUser({ ...editedUser, [name]: value });
    setIsFormChanged(true);
    // Clear the error message if the field is valid now
    setErrors({ ...errors, [name]: "" });
  };
  const handleSubmit = () => {
    const formFields = ["full_name", "email", "mobile", "bank_account", "description", "salon_name", "password", "account_name"];
    const newErrors = {};
    let formValid = true;

    // Check for empty fields
    formFields.forEach((field) => {
      if (!editedUser[field]) {
        newErrors[field] = errorMessages[field];
        formValid = false;
      }
    });

    if (!formValid) {
      setErrors(newErrors);

      return;
    }

    if (isFormChanged) {
      dispatch(action.updateUserData(editedUser));
      toast.success("تم التحديث بنجاح");
      toggle();
    } else {
      toast.info("لم يتم إجراء أي تغييرات");
    }
  };
  const closeButton = () => {
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>تعديل بيانات مقدمي الخدمات</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="full_name">اسم المستخدم</Label>
            <Input
              type="text"
              name="full_name"
              id="full_name"
              value={editedUser.full_name || ""}
              onChange={handleInputChange}
              invalid={!!errors.full_name}
            />
            {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="email">البريد الإلكتروني</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={editedUser.email || ""}
              disabled
              invalid={!!errors.email}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="password">كلمة المرور</Label>
            <Input
              type="text"
              name="password"
              id="password"
              value={editedUser.password || ""}
              onChange={handleInputChange}
              invalid={!!errors.password}
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="mobile">رقم الهاتف</Label>
            <Input
              type="text"
              name="mobile"
              id="mobile"
              value={editedUser.mobile || ""}
              onChange={handleInputChange}
              invalid={!!errors.mobile}
            />
            {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="account_name">اسم البنك </Label>
            <Input
              type="text"
              name="account_name"
              id="account_name"
              value={editedUser.account_name || ""}
              onChange={handleInputChange}
              invalid={!!errors.account_name}
            />
            {errors.account_name && <div className="invalid-feedback">{errors.account_name}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="bank_account">حساب البنكي</Label>
            <Input
              type="text"
              name="bank_account"
              id="bank_account"
              value={editedUser.bank_account || ""}
              onChange={handleInputChange}
              invalid={!!errors.bank_account}
            />
            {errors.bank_account && <div className="invalid-feedback">{errors.bank_account}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="description">وصف</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={editedUser.description || ""}
              onChange={handleInputChange}
              invalid={!!errors.description}
            />
            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="salon_name">اسم الصالون</Label>
            <Input
              type="text"
              name="salon_name"
              id="salon_name"
              value={editedUser.salon_name || ""}
              onChange={handleInputChange}
              invalid={!!errors.salon_name}
            />
            {errors.salon_name && <div className="invalid-feedback">{errors.salon_name}</div>}

          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeButton}>
          إلغاء
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={!isFormChanged}>
          حفظ التغييرات
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditFormModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  editData: PropTypes.object.isRequired,
};

export default EditFormModal;
