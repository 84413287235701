// NewSuperAdminForm.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import { Form, Button, Spinner, Col, FormGroup, Label, Input } from 'reactstrap';
import {toast} from 'react-toastify';
const roleTranslations = {
    admin: 'مشرف',
    super_admin: 'مشرف عام',
};

const NewSuperAdminForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner
    const { admins } = useSelector(state => state.settings);

    const emailExists = (newEmail) => {
        return admins.some(admin => admin.email === newEmail);
    };



    const validateForm = () => {
        if (!admins || admins.length === 0) {
            toast.error('لا يمكن إرسال النموذج. .');
            return false;
        }
        if (emailExists(email)) {
            toast.error('البريد الإلكتروني موجود بالفعل.');
            return false;
        }
        return email && password && role;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsLoading(true); // Show spinner
        setTimeout(() => { // Simulate form submission delay
            const payload = {
                email,
                password,
                role: role,
            };
            dispatch(actions.createSuperAdminRequest(payload));
            setEmail('');
            setPassword('');
            setRole('');
            setIsLoading(false); // Hide spinner after 3 seconds
        }, 3000);
    };


    const scrollableContainer = {
        maxHeight: "350px",
        overflowY: "hidden",
    }
    return (


        <Col className='py-3' style={scrollableContainer}>
            <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3" controlid="formBasicEmail">
                    <Label>عنوان البريد الإلكتروني</Label>
                    <Input
                        type="email"
                        placeholder="أدخل البريد الإلكتروني"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>

                <FormGroup className="mb-3" controlid="formBasicPassword">
                    <Label>كلمة المرور</Label>
                    <Input
                        type="text"
                        placeholder="كلمة المرور"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-3" controlid="formBasicRole">
                    <Label>الدور</Label>
                    <Input
                        type="select"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                    >
                        <option value="" disabled>
                            اختر الدور
                        </option>
                        <option value="admin">{}{roleTranslations.admin}</option>
                        <option value="super_admin">{roleTranslations.super_admin}</option>
                    </Input>
                </FormGroup>


                <Button variant="primary" type="submit" className='w-100 mt-3 mb-3 font-size-14' disabled={isLoading}>
                    {isLoading ? <Spinner size="sm" /> : ' إنشاء مشرف عام'}
                </Button>
            </Form>
        </Col>


    );
};

export default NewSuperAdminForm;