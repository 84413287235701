// actions.js

import * as actionTypes from './actionTypes';

// Action creators for fetching service providers
export const fetchServiceProvidersRequest = () => ({
  type: actionTypes.FETCH_SERVICE_PROVIDERS_REQUEST,
});

export const fetchServiceProvidersSuccess = (serviceProviders) => ({
  type: actionTypes.FETCH_SERVICE_PROVIDERS_SUCCESS,
  serviceProviders,
});

export const fetchServiceProvidersFailure = (error) => ({
  type: actionTypes.FETCH_SERVICE_PROVIDERS_FAILURE,
  error,
});

// Action creators for adding a new service provider
export const addServiceProviderRequest = (newServiceProvider) => ({
  type: actionTypes.ADD_SERVICE_PROVIDER_REQUEST,
  newServiceProvider,
});

export const addServiceProviderSuccess = (newServiceProvider) => ({
  type: actionTypes.ADD_SERVICE_PROVIDER_SUCCESS,
  newServiceProvider,
});

export const addServiceProviderFailure = (error) => ({
  type: actionTypes.ADD_SERVICE_PROVIDER_FAILURE,
  error,
});

// Action creators for removing a service provider
export const removeServiceProviderRequest = (serviceProviderId) => ({
  type: actionTypes.REMOVE_SERVICE_PROVIDER_REQUEST,
  serviceProviderId,
});

export const removeServiceProviderSuccess = (serviceProviderId) => ({
  type: actionTypes.REMOVE_SERVICE_PROVIDER_SUCCESS,
  serviceProviderId,
});

export const removeServiceProviderFailure = (error) => ({
  type: actionTypes.REMOVE_SERVICE_PROVIDER_FAILURE,
  error,
});



// Action creators for fetching service provider joining requests
export const fetchServiceProviderRequestsRequest = () => ({
  type: actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_REQUEST,
});

export const fetchServiceProviderRequestsSuccess = (requests) => ({
  type: actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_SUCCESS,
  requests,
});

export const fetchServiceProviderRequestsFailure = (error) => ({
  type: actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_FAILURE,
  error,
});



// actions.js



// Action creators for approving a service provider
export const approveServiceProviderRequest = (serviceProviderId) => ({
  type: actionTypes.APPROVE_SERVICE_PROVIDER_REQUEST,
  serviceProviderId,
});

export const approveServiceProviderSuccess = (serviceProviderId) => ({
  type: actionTypes.APPROVE_SERVICE_PROVIDER_SUCCESS,
  serviceProviderId,
});

export const approveServiceProviderFailure = (error) => ({
  type: actionTypes.APPROVE_SERVICE_PROVIDER_FAILURE,
  error,
});

// Action creators for rejecting a service provider
export const rejectServiceProviderRequest = (serviceProviderId) => ({
  type: actionTypes.REJECT_SERVICE_PROVIDER_REQUEST,
  serviceProviderId,
});

export const rejectServiceProviderSuccess = (serviceProviderId) => ({
  type: actionTypes.REJECT_SERVICE_PROVIDER_SUCCESS,
  serviceProviderId,
});

export const rejectServiceProviderFailure = (error) => ({
  type: actionTypes.REJECT_SERVICE_PROVIDER_FAILURE,
  error,
});



export const updateAccountStatusRequest = (accountId, newStatus) => ({
  type: actionTypes.UPDATE_ACCOUNT_STATUS_REQUEST,
  payload: { accountId, newStatus },
});

export const updateAccountStatusSuccess = (accountId, newStatus) => ({
  type: actionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS,
  payload: { accountId, newStatus },
});


export const clearError = () => ({
  type: actionTypes.CLEAR_ERROR,
});


export const updateUserData = (userData) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: userData,
});
