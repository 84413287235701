import { put, takeLatest, call } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import appwritPointes from "helpers/appwrite/appwritePointes"
import { databases } from "helpers/appwrite/appwriteConfig"
import generateRandomPassword from "store/serviceProviders/generatedPass"
import sms from "helpers/api/sms"
import { ID } from "appwrite"



const addServiceProviderApi = async newServiceProvider => {
}

const removeServiceProviderApi = async serviceProviderId => {
  // Implement removing a service provider from your backend
  try {
    await databases.deleteDocument(
      appwritPointes.databaseID, // Replace with your collection ID
      appwritPointes.providers, // Replace with your document ID
      serviceProviderId
    )
  } catch (error) {
    throw error
  }
}

// Example API functions for approving and rejecting service providers
const approveServiceProviderApi = async serviceProviderId => {
  // Implement the API call to approve the service
  const { email, full_name, mobile } = serviceProviderId

  const generatedPass = generateRandomPassword(8)
  try {
    const provider = {
      full_name: full_name,
      salon_name: " صالون",
      mobile: mobile,
      email: email,
      account_status: "active",
      password: generatedPass,
      description: "للعناية بالجمال والمظهر الشخصي",
    }
    let result = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.providers,
      ID.unique(),
      provider
    )
    sms(result)

  } catch (error) {
    throw error
  }
}

const rejectServiceProviderApi = async (collId, doId) => {
  // Implement the API call to reject the service provider
  await databases.deleteDocument(
    appwritPointes.databaseID,
    collId,
    doId
  )
}

const fetchServiceProvidersApi = async () => {
  const result = await databases.listDocuments(
    appwritPointes.databaseID,
    appwritPointes.providers
  );
  return result.documents;
};

const fetchServiceProviderRequestsApi = async () => {
  const result = await databases.listDocuments(
    appwritPointes.databaseID,
    appwritPointes.joinRequest
  );
  return result.documents;
};
const filterServiceProviderRequests = (registeredProviders, requests) => {
  const registeredEmails = new Set(registeredProviders.map(provider => provider.email));
  const registeredMobiles = new Set(registeredProviders.map(provider => provider.mobile));

  return requests.filter(request => {
    const isRegistered = registeredEmails.has(request.email) || registeredMobiles.has(request.mobile);
    if (isRegistered) {
      // Optionally remove the request from the join requests list
      removeServiceProviderRequest(request.$id);
    }
    return !isRegistered;
  });
};




const removeServiceProviderRequest = async (requestId) => {
  try {
    await databases.deleteDocument(
      appwritPointes.databaseID,
      appwritPointes.joinRequest,
      requestId
    );
  } catch (error) {
    console.error('Error removing service provider request:', error);
  }
};


const UpdateStatus = async (accountId, newStatus) => {
  // Implement fetching requests from your backend
  const result = await databases.updateDocument(
    appwritPointes.databaseID,
    appwritPointes.providers,
    accountId,
    {
      account_status: newStatus
    }
  )
  return result.documents
}


const updateUserApi = async (userData) => {
  try {
    // Define a mapping between userData keys and updatedFormData keys
    const keyMapping = {
      full_name: 'full_name',
      location: 'address',
      bank_account: 'bank_account',
      account_name: 'account_name',
      description: 'description',
      email: 'email',
      password: 'password',
      mobile: 'mobile',
      salon_name: 'salon_name',
    };

    // Create an empty object to hold the updated data
    const updatedFormData = {};

    // Loop through each property in the mapping object
    for (const key in keyMapping) {
      // Check if the userData property value is not null or undefined
      if (userData[key] !== null && userData[key] !== undefined) {
        // Use the mapped key for the updatedFormData
        updatedFormData[keyMapping[key]] = userData[key];
      }
    }

    // Update the document with the filtered data
    await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.providers,
      userData.$id,
      updatedFormData
    );
  } catch (error) {
    throw error; // Re-throw the error for higher-level error handling
  }
};



function* fetchServiceProviders() {
  try {
    const serviceProviders = yield call(fetchServiceProvidersApi)
    yield put(actions.fetchServiceProvidersSuccess(serviceProviders))
  } catch (error) {
    yield put(actions.fetchServiceProvidersFailure(error))
  }
}
function* addServiceProvider(action) {
  try {
    const newServiceProvider = yield call(
      addServiceProviderApi,
      action.newServiceProvider
    )
    yield put(actions.addServiceProviderSuccess(newServiceProvider))
  } catch (error) {
    yield put(actions.addServiceProviderFailure(error))
  }
}
// Worker saga for fetching service provider joining requests
function* fetchServiceProviderRequests() {
  try {
    const registeredProviders = yield call(fetchServiceProvidersApi);
    const requests = yield call(fetchServiceProviderRequestsApi);
    
    const filteredRequests = filterServiceProviderRequests(registeredProviders, requests);
    
    yield put(actions.fetchServiceProviderRequestsSuccess(filteredRequests));
  } catch (error) {
    yield put(actions.fetchServiceProviderRequestsFailure(error));
  }
}



function* removeServiceProvider(action) {
  try {
    yield call(removeServiceProviderApi, action.serviceProviderId)
    yield put(actions.removeServiceProviderSuccess(action.serviceProviderId))
  } catch (error) {
    yield put(actions.removeServiceProviderFailure(error))
  }
}
// Worker saga for approving a service provider
function* approveServiceProvider(action) {
  try {
    const { $collectionId, $id } = action.serviceProviderId
    yield call(approveServiceProviderApi, action.serviceProviderId)
    yield put(actions.approveServiceProviderSuccess(action.serviceProviderId))
    yield put(actions.rejectServiceProviderRequest({ $collectionId, $id }))
    yield put(actions.fetchServiceProvidersRequest())
    
  } catch (error) {
    yield put(actions.approveServiceProviderFailure(error.message))
  }
}
// Worker saga for rejecting a service provider
function* rejectServiceProvider(action) {
  try {
    const { $collectionId, $id } = action.serviceProviderId
    yield call(rejectServiceProviderApi, $collectionId, $id)
    yield call(action.fetchServiceProviderRequestsRequest())
    yield put(actions.rejectServiceProviderSuccess($id))
  } catch (error) {
    yield put(actions.rejectServiceProviderFailure(error))
  }
}


function* updateAccountStatus(action) {
  try {
    // Make the API call to update the account status
    const { accountId, newStatus } = action.payload;
    yield call(UpdateStatus, accountId, newStatus);
    const serviceProviders = yield call(fetchServiceProvidersApi)
    yield put(actions.fetchServiceProvidersSuccess(serviceProviders))
  } catch (error) {
    throw error
  }
}

function* updateUser(action) {
  try {
    yield call(updateUserApi, action.payload);
    const serviceProviders = yield call(fetchServiceProvidersApi)
    yield put(actions.fetchServiceProvidersSuccess(serviceProviders))
  } catch (error) {
    throw error
  }
}


// Watcher sagas
export function* watchServiceProviders() {
  yield takeLatest(
    actionTypes.FETCH_SERVICE_PROVIDERS_REQUEST,
    fetchServiceProviders
  )
  yield takeLatest(
    actionTypes.REMOVE_SERVICE_PROVIDER_REQUEST,
    removeServiceProvider
  )
  yield takeLatest(actionTypes.ADD_SERVICE_PROVIDER_REQUEST, addServiceProvider)
  yield takeLatest(
    actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_REQUEST,
    fetchServiceProviderRequests
  )
  yield takeLatest(
    actionTypes.REJECT_SERVICE_PROVIDER_REQUEST,
    rejectServiceProvider
  )
  yield takeLatest(
    actionTypes.APPROVE_SERVICE_PROVIDER_REQUEST,
    approveServiceProvider
  )

  yield takeLatest(actionTypes.UPDATE_ACCOUNT_STATUS_REQUEST, updateAccountStatus);
  yield takeLatest(actionTypes.UPDATE_USER_DATA, updateUser);
}

export default watchServiceProviders
