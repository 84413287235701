import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  FormFeedback,
  Nav
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
// Import the fetchCategoriesRequest action
import {
  fetchCategoriesRequest,
  fetchTypesRequest,
  fetchServiceProvidersRequest
} from "../../store/actions"

const AddServiceForm = ({ handleAddService }) => {
  // Retrieve categories and loading state from the Redux store
  const { categories, catLoadin, types, typeLoadin } = useSelector(
    state => state.service
  )

  const { serviceProviders, poviderLoading } = useSelector(
    state => state.serviceProviders
  )
  // Initialize state variables
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [selectedImageFile, setSelectedImageFile] = useState(null)

  const [formData, setFormData] = useState({
    name: "",
    service_price: 0.0, // Empty string instead of 0.0
    description: "",
    img_file: "",
    service_type: "",
    category_type: "",
    at_home_service_price: 0.0, // Empty string instead of 0.0
    avg_execute_time: 0.0, // Empty string instead of 0.0
    provider: ""
  })

  const [errors, setErrors] = useState({})

  // Initialize Redux dispatch function
  const dispatch = useDispatch()

  // Fetch categories from the backend when the component mounts
  useEffect(() => {
    dispatch(fetchCategoriesRequest())
    dispatch(fetchTypesRequest())
    dispatch(fetchServiceProvidersRequest())
  }, [dispatch, fetchTypesRequest])

  function toggleTab(tab) {
    // Function to switch between tabs
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const validateStep1 = () => {
    const newErrors = {}
    if (!formData.name.trim()) newErrors.serviceName = "اسم الخدمة مطلوب."
    if (!(formData.service_price > 0)) newErrors.servicePrice = "يجب إدخال سعر الخدمة"
    if (!formData.service_type.trim()) newErrors.serviceType = "نوع الخدمة مطلوب."
    if (!formData.category_type.trim()) newErrors.categoryType = "قسم الخدمة مطلوب."

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const validateStep2 = () => {
    const newErrors = {}
    if (!formData.description.trim()) newErrors.serviceDescription = "الوصف مطلوب."
    if (!(formData.avg_execute_time > 0))
      newErrors.avgExecuteTime = "الوقت المتوقع"
    if (!(formData.at_home_service_price > 0) && formData.service_type !== "المركز")
      newErrors.atHomeServiceCost = "يجب إدخال سعر الخدمة في المنزل"
    if (selectedImageFile == null) newErrors.imgFile = "يرجى تحميل صورة للخدمة."

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleNextStep = () => {
    // Function to handle moving to the next step
    if (activeTab === 1) {
      if (validateStep1()) {
        setactiveTab(activeTab + 1)
        setPassedSteps([...passedSteps, activeTab + 1])
      }
    } else if (activeTab === 2) {
      if (validateStep2()) {
        setactiveTab(activeTab + 1)
        setPassedSteps([...passedSteps, activeTab + 1])
      }
    }
  }

  const handlePrevStep = () => {
    // Function to handle moving to the previous step
    if (activeTab > 1) {
      setactiveTab(activeTab - 1)
    }
  }

  const handleSubmit = e => {
    // Function to handle form submission
    e.preventDefault()
    if (validateStep1() && validateStep2()) {

      const newData = {
        provider_id: formData.provider,
        name: formData.name,
        service_price: parseFloat(formData.service_price), // Convert to float
        img_file: selectedImageFile, // The File object of the selected image
        description: formData.description,
        service_type: formData.service_type,
        category_type: formData.category_type,
        avg_execute_time: parseFloat(formData.avg_execute_time), // Convert to float
        at_home_service_price: parseFloat(formData.at_home_service_price), // Convert to float
      }
      handleAddService(newData)

    }
    setFormData({
      name: "",
      service_price: 0.0,
      description: "",
      img_file: "",
      service_type: "",
      category_type: "",
      at_home_service_price: 0.0,
      avg_execute_time: 0.0,
    })
    setactiveTab(1) // Reset to the first step after submission
  }


  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file) {
      if (isFileValid(file)) {
        if (file.size <= MAX_FILE_SIZE) {

          setSelectedImageFile(file)
          toast.success("تم تحميل الصورة بنجاح!", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("ألا تتجاوز الصورة الحد الأقصى المسموح به (1 ميجابايت). الرجاء اختيار ملف أصغر.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } else {

        toast.error("نوع الملف غير صالح. يرجى تحميل صورة.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const isFileValid = (file) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return allowedFileTypes.includes(file.type);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div
                  id="addproduct-nav-pills-wizard"
                  className="twitter-bs-wizard"
                >
                  <Row>
                    <Col lg={3}>
                      <Nav
                        pills
                        className="flex-column"
                        id="twitter-bs-wizard-tab"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 1,
                              done: activeTab <= 3 && activeTab > 1,
                            })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">تفاصيل الخدمة</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 2,
                              done: activeTab <= 3 && activeTab > 2,
                            })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">التسعير والوصف</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 3,
                            })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">النشر</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col lg={9}>
                      <TabContent
                        activeTab={activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <FormGroup>
                              <Label for="service-name">اسم الخدمة</Label>
                              <Input
                                type="text"
                                id="service-name"
                                placeholder=" اسم الخدمة"
                                value={formData.name}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    name: e.target.value,
                                  })
                                }
                                invalid={!!errors.serviceName}
                              />
                              <FormFeedback>{errors.serviceName}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Label for="service-price">سعر الخدمة</Label>
                              <Input
                                type="number"
                                id="service-price"
                                placeholder="سعر الخدمة "
                                value={formData.service_price}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    service_price: e.target.value,
                                  })
                                }
                                invalid={!!errors.servicePrice}
                              />
                              <FormFeedback>{errors.servicePrice}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Label for="service-type">نوع الخدمة</Label>
                              <Input
                                type="select"
                                id="service-type"
                                value={formData.service_type}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    service_type: e.target.value,
                                  })
                                }
                                invalid={!!errors.serviceType}
                              >
                                <option value="" disabled>
                                  اختر نوع الخدمة
                                </option>
                                {types.map(type => (
                                  <option key={type.$id} value={type.title}>
                                    {type.title}
                                  </option>
                                ))}
                              </Input>
                              <FormFeedback>{errors.serviceType}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Label for="category_type">قسم الخدمة</Label>
                              <Input
                                type="select"
                                id="category_type"
                                value={formData.category_type}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    category_type: e.target.value,
                                  })
                                }
                                invalid={!!errors.categoryType}
                              >
                                <option value="" disabled>
                                  قسم الخدمة
                                </option>
                                {Array.isArray(categories) &&
                                  categories.map(category => (
                                    <option key={category.$id} value={category.title}>
                                      {category.title}
                                    </option>
                                  ))}
                              </Input>
                              <FormFeedback>{errors.categoryType}</FormFeedback>
                            </FormGroup>

                            <div className="d-flex justify-content-between">
                              <Button color="primary" onClick={handleNextStep}>
                                التالي
                              </Button>
                            </div>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <Form>
                            <FormGroup>
                              <Label for="service-description">وصف</Label>
                              <Input
                                type="textarea"
                                id="service-description"
                                placeholder=" وصف"
                                value={formData.description}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    description: e.target.value,
                                  })
                                }
                                invalid={!!errors.serviceDescription}
                              />
                              <FormFeedback>{errors.serviceDescription}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Label for="avg-execute-time">
                                الوقت المتوقع للتنفيذ (بالدقائق)
                              </Label>
                              <Input
                                type="number"
                                id="avg-execute-time"
                                placeholder=" متوسط وقت التنفيذ"
                                value={formData.avg_execute_time}
                                min="0"
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    avg_execute_time: e.target.value,
                                  })
                                }
                                invalid={!!errors.avgExecuteTime}
                              />
                              <FormFeedback>{errors.avgExecuteTime}</FormFeedback>
                            </FormGroup>

                            {
                              formData.service_type !== "المركز" && (
                                <FormGroup>
                                  <Label for="at-home-service">سعر الخدمة في المنزل</Label>
                                  <Input
                                    type="number"
                                    id="at-home-service"
                                    placeholder=" سعر الخدمة في المنزل"
                                    value={formData.at_home_service_price}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        at_home_service_price: e.target.value,
                                      })
                                    }
                                    invalid={!!errors.atHomeServiceCost}
                                  />
                                  <FormFeedback>{errors.atHomeServiceCost}</FormFeedback>
                                </FormGroup>
                              )
                            }

                            <FormGroup>
                              <Label for="provider"> مقدمي الخدمات</Label>
                              <Input
                                type="select"
                                id="provider"
                                value={formData.provider}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    provider: e.target.value,
                                  })
                                }
                                invalid={!!errors.provider}
                              >
                                <option value="" disabled>
                                  مقدمي الخدمات
                                </option>
                                {Array.isArray(serviceProviders) &&
                                  serviceProviders.map(provider => (
                                    <option
                                      key={provider.$id}
                                      value={provider.$id}
                                    >
                                      {provider.salon_name}
                                    </option>
                                  ))}
                              </Input>
                              <FormFeedback>{errors.provider}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Label for="service-image">صورة الخدمة</Label>
                              <Input
                                type="file"
                                id="service-image"
                                accept="image/*"
                                onChange={handleImageChange}
                                invalid={!!errors.imgFile}
                              />
                              <FormFeedback>{errors.imgFile}</FormFeedback>
                            </FormGroup>

                            <div className="d-flex justify-content-between">
                              <Button color="secondary" onClick={handlePrevStep}>
                                السابق
                              </Button>
                              <Button color="primary" onClick={handleNextStep}>
                                التالي
                              </Button>
                            </div>
                          </Form>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div className="text-center">
                            <h5 className="mt-3">نشر الخدمة</h5>
                            <p className="text-muted">
                              عند الضغط على زر "نشر" ستتم إضافة الخدمة بشكل دائم
                            </p>
                            <div className="text-left mt-4">
                              <h6>تفاصيل الخدمة:</h6>
                              <ul className="list-unstyled">
                                <li><strong>اسم الخدمة:</strong> {formData.name}</li>
                                <li><strong>سعر الخدمة:</strong> {formData.service_price}</li>
                                <li><strong>وصف الخدمة:</strong> {formData.description}</li>
                                <li><strong>نوع الخدمة:</strong> {formData.service_type}</li>
                                <li><strong>قسم الخدمة:</strong> {formData.category_type}</li>
                                <li><strong>سعر الخدمة في المنزل:</strong> {formData.at_home_service_price}</li>
                                <li><strong>الوقت المتوقع للتنفيذ:</strong> {formData.avg_execute_time} دقيقة</li>
                                <li><strong>مقدم الخدمة:</strong> {formData.provider}</li>
                                {selectedImageFile && (
                                  <li>
                                    <strong>صورة الخدمة:</strong>
                                    <img
                                      src={URL.createObjectURL(selectedImageFile)}
                                      alt="Service"
                                      style={{ width: '200px', height: '130px' }}
                                    />
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <Button color="secondary" onClick={() => toggleTab(activeTab - 1)}>
                                السابق
                              </Button>
                              <Button color="primary" onClick={handleSubmit}>
                                نشر
                              </Button>
                            </div>
                          </div>
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddServiceForm
