export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "API_ERROR"
export const FETCH_USER_INFO = "FETCH_USER_INFO"
export const RESET_USER_INFO = "RESET_USER_INFO"
export const STORE_USER_INFO = "STORE_USER_INFO"

export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST"
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS"
export const UPDATE_USER_INFO_FAILURE = "UPDATE_USER_INFO_FAILURE"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST"
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS"
export const CHANGE_EMAIL_FAILURE = "CHANGE_EMAIL_FAILURE"
export const CLEAR_EMAIL_CHANGE_ERROR = "CLEAR_EMAIL_CHANGE_ERROR"
export const CLEAR_PASSWORD_CHANGE_ERROR = "CLEAR_PASSWORD_CHANGE_ERROR"

export const CLEAR_PASSWORD_CHANGE_SUCCESS = "CLEAR_PASSWORD_CHANGE_SUCCESS"
export const CLEAR_EMAIL_CHANGE_SUCCESS = "CLEAR_EMAIL_CHANGE_SUCCESS"
export const SAVE_USER_TOKEN = 'SAVE_USER_TOKEN';