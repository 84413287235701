import React, { useState } from 'react';
import { Col, Form, FormGroup, Label, Input, Button, Row, Card, CardImg } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import { toast } from 'react-toastify';





function BannerUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedBanners, setUploadedBanners] = useState([]);
  const { bannerLinks } = useSelector(state => state.settings);
  const dispatch = useDispatch();


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      toast.info('يرجى تحديد ملف أولاً!');
      return;
    }
    dispatch(actions.uploadBannerRequest(selectedFile));
    setUploadedBanners([...uploadedBanners, URL.createObjectURL(selectedFile)]);
    setSelectedFile(null)
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file) {
      if (isFileValid(file)) {
        if (file.size <= MAX_FILE_SIZE) {

          setSelectedFile(file)
          toast.success("تم تحميل الصورة بنجاح!", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("ألا تتجاوز الصورة الحد الأقصى المسموح به (1 ميجابايت). الرجاء اختيار ملف أصغر.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } else {

        toast.error("نوع الملف غير صالح. يرجى تحميل صورة.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const isFileValid = (file) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return allowedFileTypes.includes(file.type);
  };

  const handleDeleteImage = (index, file) => {

    const fileData = {
      documentId: file.$id,
      url: file.image_url,
      img_id: file.img_id,
    }


    const newBanners = uploadedBanners.filter((_, i) => i !== index);
    setUploadedBanners(newBanners);
    dispatch(actions.deleteDataRequest(fileData))
  };
  const scrollableContainer = {
    maxHeight: "350px",
    overflowY: "auto",
  }
  return (
    <Row className='d-flex justify-content-center align-items-center bg-white py-3 '>
      <Col xl={5}>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="bannerUpload">تحميل البانر</Label>
            <Input type="file"
              name="file"
              id="bannerUpload"
              accept="image/*"
              onChange={handleImageChange}
            />
          </FormGroup>
          <Button type="submit" color="primary">تحميل البانر</Button>
        </Form>
      </Col>

      <Col xl={5}>
        <Row className="scrollable-container" style={{ maxHeight: "350px", overflowY: "auto" }}>
          {[...uploadedBanners, ...bannerLinks].map((bannerUrl, index) => (
            <Col key={index} sm={6} md={4} lg={3} className="mb-3 position-relative">
              <Card>
              <i className="bx bx-trash position-absolute top-0 end-0 m-2" style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteImage(index, bannerUrl)}/>
                <CardImg top width="100%" src={bannerUrl.image_url} alt="Banner Image" />
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>

  );
}

export default BannerUpload;
