import React, { useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserInfo,
  changeEmailRequest,
  changePasswordRequest,
  clearEmailChangeError,
  clearPasswordChangeError,
  changePasswordFailure,
  clearEmailChangeSuccess,
  clearPasswordChangeSuccess,
  changeEmailFailure
} from "../../store/actions";
import { fetchServicesRequest, fetchOrdersRequest } from "../../store/actions";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import UserModal from "./UserModal";
import ApexRevenue from "./ApexRevenue";
import TableContainer from "components/Common/TableContainer";
import { Pdate, Ddate, Name, Idno, Budget } from "./AccCol";

const UserProfile = () => {
  document.title = "حساب تعريفي  | DBsaloni";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState("1");

  const {
    passwordChangeError,
    passwordChangeSucess,
    emailChangeError,
    emailChangeSucess,
  } = useSelector((state) => state.Login);

  const user = useSelector((state) => state.Login.user);
  const totalCompletedOrders = useSelector(
    (state) => state.orders.totalCompletedOrders
  );
  const totalPendingOrders = useSelector(
    (state) => state.orders.totalPendingOrders
  );

  
  
  const services = useSelector((state) => state.service.services);

  useEffect(() => {

    const sessionId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });
    if (sessionId) {
      dispatch(fetchUserInfo(sessionId));
    }
  }, [dispatch]);

  useEffect(() => {
    setReports([
      {
        title: "الطلبات المنجزة",
        iconClass: "bx-check-circle",
        description: totalCompletedOrders,
      },
      {
        title: "الطلبات المعلقة",
        iconClass: "bx-hourglass",
        description: totalPendingOrders,
      },
      {
        title: "إجمالي عدد الخدمات ",
        iconClass: "bx-package",
        description: services.length,
      },
    ]);
  }, [totalCompletedOrders, totalPendingOrders]);

  useEffect(() => {
    dispatch(fetchServicesRequest());
    dispatch(fetchOrdersRequest());
  }, [dispatch]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };



  const tog_center = () => {
    setmodal_center(!modal_center);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  const [reports, setReports] = useState([
    {
      title: "الطلبات المنجزة",
      iconClass: "bx-check-circle",
      description: totalCompletedOrders,
    },
    {
      title: "الطلبات المعلقة",
      iconClass: "bx-hourglass",
      description: totalPendingOrders,
    },
    {  title: "إجمالي عدد الخدمات ", iconClass: "bx-package", description: services.length },
  ]);

  const columns = useMemo(
    () => [
    
      {
        Header: "اسم الخدمة",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => <Name {...cellProps} />,
      },
      {
        Header: " نوع الخدمة	 ",
        accessor: "service_type",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => <Pdate {...cellProps} />,
      },
      {
        Header: "السعر",
        accessor: "service_price",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => <Ddate {...cellProps} />,
      },
      {
        Header: "قسم الخدمة",
        accessor: "service_category",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => <Budget {...cellProps} />,
      },
    ],
    []
  );

  const handleUpdateSuccess = () => {
    setUpdateSuccess(true);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 3000);
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (newPassword.length < 7) {
      const error = new Error("يجب أن تكون كلمة المرور الجديدة على الأقل 8 أحرف");
      dispatch(changePasswordFailure(error));
      return;
    }
    if (confirmPassword !== newPassword) {
      const error = new Error("كلمة المرور غير متطابقة");
      dispatch(changePasswordFailure(error));
      return;

    }


    try {
      dispatch(changePasswordRequest({newPassword }));
      setmodal_center(false);
    } catch (error) {
      dispatch(changePasswordFailure(error));
    }
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    const newEmail = e.target.newEmail.value;
    const password = e.target.password.value;

    if (!isValidEmail(newEmail)) {
      dispatch(changeEmailFailure(error));

    }

    try {
      const emailAndPasswordData = {
        newEmail,
        password,
      };
      dispatch(changeEmailRequest(emailAndPasswordData));
      setmodal_center(false);
    } catch (error) {
      dispatch(changeEmailFailure(error));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClearEmailChangeError = () => {
    dispatch(clearEmailChangeError());
  };

  const handleClearPasswordChangeError = () => {
    dispatch(clearPasswordChangeError());
  };

  const handleClearPasswordChangeSucess = () => {
    dispatch(clearPasswordChangeSuccess());
  };

  const handleClearEmailChangeSucess = () => {
    dispatch(clearEmailChangeSuccess());
  };










  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {updateSuccess && (
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >

              <i className="mdi mdi-check-all me-2"></i>
              تم تغيير اسم المستخدم الخاص بك بنجاح
              {emailChangeError}
              <button
                type="button"
                className="btn-close"
                onClick={() => setUpdateSuccess(false)}
              ></button>
            </UncontrolledAlert>
          )}


          {emailChangeError && typeof emailChangeError === "string" && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-close-circle me-2"></i>
              {emailChangeError}
              <button
                type="button"
                className="btn-close"
                onClick={handleClearEmailChangeError}
              ></button>
            </UncontrolledAlert>
          )}

          {emailChangeSucess && (
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-check-all me-2"></i>
              تم تحديث بريد الالكتروني بنجاح.
              <button
                type="button"
                className="btn-close"
                onClick={handleClearEmailChangeSucess}
              ></button>
            </UncontrolledAlert>
          )}

          {passwordChangeSucess && (
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-check-all me-2"></i>
              تم تحديث كلمة المرور بنجاح.
              <button
                type="button"
                className="btn-close"
                onClick={handleClearPasswordChangeSucess}
              ></button>
            </UncontrolledAlert>
          )}

          {passwordChangeError && typeof passwordChangeError === "string" && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-close-circle me-2"></i>
              {passwordChangeError}
              <button
                type="button"
                className="btn-close"
                onClick={handleClearPasswordChangeError}
              ></button>
            </UncontrolledAlert>
          )}

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">

                <CardBody className="pt-0">
                  <Row>

                    <Col sm={0}>
                      <div className="pt-4">
                        <div className="p-4  text-center">
                          <Button
                            onClick={openModal}
                            className="btn btn-primary  btn-lg"
                          >
                            تعديل بيانات الشخصية{" "}
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Button>

                          {isModalOpen && (
                            <UserModal
                              user={user}
                              closeModal={closeModal}
                              handleUpdateSuccess={handleUpdateSuccess}
                            />
                          )}

                          <div className="mt-1">
                            <Button
                              className="btn btn-primary  btn-lg"
                              onClick={() => {
                                tog_center();
                              }}
                            >
                              تغيير كلمة المرور{" "}
                              <i className="mdi mdi-arrow-right ms-1"></i>
                            </Button>

                            <Modal
                              isOpen={modal_center}
                              toggle={() => {
                                tog_center();
                              }}
                              centered
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setmodal_center(false);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    تغيير كلمة المرور
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                      toggle("2");
                                    }}
                                  >
                                    تغيير بريد الإلكتروني
                                  </NavLink>
                                </NavItem>
                              </Nav>

                              <TabContent
                                activeTab={activeTab}
                                className="p-3 "
                              >
                                <TabPane tabId="1">
                                  <Row>
                                    <Col sm="12">
                                      <form onSubmit={handleSubmitPassword}>
                                        {/* <FormGroup>
                                          <label htmlFor="currentPassword">
                                            كلمة المرور الحالية:
                                          </label>
                                          <input
                                            type="password"
                                            id="currentPassword"
                                            className="form-control"
                                            placeholder="كلمة المرور الحالية"
                                          />
                                        </FormGroup> */}
                                        <FormGroup>
                                          <label htmlFor="newPassword">
                                            كلمة المرور الجديدة:
                                          </label>
                                          <input
                                            type="text"
                                            id="newPassword"
                                            className="form-control"
                                            placeholder="كلمة المرور الجديدة"
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <label htmlFor="confirmPassword">
                                            تأكيد كلمة المرور :  </label>
                                          <input
                                            type="text"
                                            id="confirmPassword"
                                            className="form-control"
                                            placeholder=" تأكيد كلمة المرور "
                                            required
                                          />
                                        </FormGroup>
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                        >
                                          حفظ
                                        </button>
                                      </form>
                                    </Col>
                                  </Row>
                                </TabPane>



                                <TabPane tabId="2">
                                  <Row>
                                    <Col sm="12">
                                      <form onSubmit={handleSubmitEmail}>
                                        <FormGroup>
                                          <label htmlFor="newEmail">
                                            البريد الإلكتروني الجديد:
                                          </label>
                                          <input
                                            type="email"
                                            id="newEmail"
                                            className="form-control"
                                            placeholder="البريد الإلكتروني الجديد"
                                            required
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <label htmlFor="password">
                                            كلمة المرور:
                                          </label>
                                          <input
                                            type="password"
                                            id="password"
                                            className="form-control"
                                            placeholder="كلمة المرور"
                                            required
                                          />
                                        </FormGroup>
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                        >
                                          حفظ
                                        </button>
                                      </form>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {user && (
                <Card>
                  <CardBody>

                    <p className=" mb-4">{user.description}</p>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">اسم المستخدم  :</th>
                            <td>{user.full_name}</td>
                          </tr>

                          <tr>
                            <th scope="row"> بريد إلكتروني :</th>
                            <td>{user.email}</td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>

            <Col xl="8">
              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className=" ">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Card>
                {/* <CardBody>
                  <CardTitle className="mb-4">إيرادات</CardTitle>
                  <div id="revenue-chart">
                    <ApexRevenue dataColors='["--bs-primary"]' />
                  </div>
                </CardBody> */}
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">خدمات</CardTitle>

                  {services.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={services || []}
                      isGlobalFilter={false}
                      customPageSize={5}
                      customPageSizeOptions={true}
                    />
                  ) : (
                    <div className="d-grid gap-2 ">
                      <Link
                        to="/servicesManagment"
                        className="btn btn-primary  btn-lg"
                        type="button"
                      >
                        إضافة خدمة جديدة{" "}
                        <i className="mdi mdi-arrow-right ms-1"></i>
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  user: state.Login.user,
});

export default connect(mapStateToProps)(UserProfile);
