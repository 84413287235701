import React from "react";

const Authmiddleware = (props) => {
 
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default Authmiddleware;
