import React, { useEffect } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GeneralSettings from './GeneralSettings';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
const newPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(actions.fetchClientsRequest())
      dispatch(actions.fetchServiceProvidersRequest())
  
    }, []);
    //meta title
    document.title = "ادارة الطلبات| DBsaloni Admin & Dashboard ";
    return (
        <>
            <Breadcrumbs title="  إعدادات " breadcrumbItem=" إعدادات عامة" />
            <GeneralSettings />
        </>
    );
}

export default newPage;
