import React from "react"
import { Container } from "reactstrap"

//components
import ProvidersList from "./ProvidersList"
import JoinRequest from "./JoinRequest"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ServiceProviderSub from "./ServiceProviderSub"

const newPage = () => {

  //meta title
  document.title = "ادارة الطلبات| DBsaloni Admin & Dashboard "

  return (
    <>
     <ServiceProviderSub />
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs
            title=" طلبات الواردة "
            breadcrumbItem="    مقدمي الخدمة"
          />
          <h2> طلبات مقدمي الخدمات </h2> 
          <JoinRequest />
          <h2>مقدمي الخدمات</h2>
          <ProvidersList
          />
        </Container>
      </div>
    </>
  )
}

export default newPage
