import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import OrderManagementDashboard from "./OrderManagementDashboard";
import { fetchOrdersRequest } from "../../store/orderManagement/actions";

const LatestOrders = (props) => {
  const { latestTransaction, fetchOrdersRequest } = props;

  if (!latestTransaction) {
    return null;
  }
  useEffect(() => {
    fetchOrdersRequest();
  }, [fetchOrdersRequest]);


  return (
    <React.Fragment>
      <Container fluid={true}>
        <div className="mb-4 h4 card-title">{props.title}</div>
      </Container>
      <OrderManagementDashboard orders={latestTransaction} />
    </React.Fragment>
  );
};

LatestOrders.propTypes = {
  latestTransaction: PropTypes.array,
  fetchOrdersRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  latestTransaction: state.orders.orders,
});

export default connect(mapStateToProps, { fetchOrdersRequest })(LatestOrders);
