import { getToken } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { messaging } from './helpers/firebase/firebaseConfig';
import { saveUserToken } from './store/actions';

const TOKEN_STORAGE_KEY = 'firebaseMessagingToken';
const TOKEN_DISPATCHED_FLAG = 'firebaseMessagingTokenDispatched';

export const requestForToken = async (dispatch) => {
  // Check if the Notification API is supported
  if (!('Notification' in window)) {
    toast.error('متصفحك لا يدعم إشعارات سطح المكتب.');
    return;
  }

  // Check if a token is already stored
  const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
  const tokenDispatched = localStorage.getItem(TOKEN_DISPATCHED_FLAG);

  if (storedToken) {
    // console.log('Token already available:', storedToken);

      await dispatch(saveUserToken(storedToken));
      localStorage.setItem(TOKEN_DISPATCHED_FLAG, 'true');

    return;
  }

  // Request permission for notifications
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    try {
      // Ensure service worker is registered
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      // console.log('Service worker registered:', registration);

      // Wait for the service worker to be active
      await navigator.serviceWorker.ready;
      // console.log('Service worker is active');

      // Retry mechanism for getting the token
      const getTokenWithRetry = async (retries = 3) => {
        for (let i = 0; i < retries; i++) {
          try {
            const currentToken = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_VAPID_KEY,
              serviceWorkerRegistration: registration,
            });

            if (currentToken && tokenDispatched !== 'true') {
              // console.log('New token received:', currentToken);
              localStorage.setItem(TOKEN_STORAGE_KEY, currentToken); // Store the token
              await dispatch(saveUserToken(currentToken));

              return;
            } else {
              // console.warn('Token not received, retrying...');
            }
          } catch (err) {
            // console.error('Error retrieving token, retrying...', err);
          }
        }
        // toast.warn('لم يتم استلام رمز الإشعار. قد لا تتلقى الإشعارات.');
      };

      await getTokenWithRetry();
    } catch (err) {
      // console.error('Error during service worker registration or token retrieval:', err);
      toast.error('حدث خطأ أثناء استرجاع رمز الإشعار: يرجى تحديث الصفحة');
    }
  } else {
    toast.error('تم رفض إذن الإشعارات. إذا قمت بإيقاف الإشعارات، لن تتمكن من تلقي إشعارات حول طلباتك.');
  }
};

// Remove token from localStorage when the tab is closed
window.addEventListener('beforeunload', () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  // localStorage.removeItem(TOKEN_DISPATCHED_FLAG);
});