// actions.js

import * as actionTypes from "./actionTypes";
  
  export const fetchSettingsRequest = () => ({
    type: actionTypes.FETCH_SETTINGS_REQUEST,
  });
  
  export const fetchSettingsSuccess = (settings) => ({
    type: actionTypes.FETCH_SETTINGS_SUCCESS,
    payload: settings,
  });
  
  export const fetchSettingsFailure = (error) => ({
    type: actionTypes.FETCH_SETTINGS_FAILURE,
    payload: error,
  });
  
  export const updateSettingsRequest = (updatedSettings) => ({
    type: actionTypes.UPDATE_SETTINGS_REQUEST,
    payload: updatedSettings,
  });
  
  export const updateSettingsSuccess = () => ({
    type: actionTypes.UPDATE_SETTINGS_SUCCESS,
  });
  
  export const updateSettingsFailure = (error) => ({
    type: actionTypes.UPDATE_SETTINGS_FAILURE,
    payload: error,
  });
  
  export const updateCommissionRate = (newRate) => ({
    type: actionTypes.UPDATE_COMMISSION_RATE,
    payload: newRate,
  });


   export const updateAboutUs = (about) => ({
    type: actionTypes.UPDATE_ABOUT_US,
    payload: about,
  });

  export const maintenance = (mode) => ({
    type: actionTypes.CHANGE_MAINANCE_MOODE,
    payload: mode,
  });

  

export const fetchAdminsRequest = () => ({
  type: actionTypes.FETCH_ADMINS_REQUEST,
});

export const fetchAdminsSuccess = (admins) => ({
  type: actionTypes.FETCH_ADMINS_SUCCESS,
  payload: admins,
});

export const fetchAdminsFailure = (error) => ({
  type: actionTypes.FETCH_ADMINS_FAILURE,
  payload: error,
});


// actions/index.js

export const changeAdminRoleRequest = (adminId, newRole) => ({
    type: actionTypes.CHANGE_ADMIN_ROLE_REQUEST,
    payload: { adminId, newRole },
});

export const changeAdminRoleSuccess = (adminId, newRole) => ({
    type: actionTypes.CHANGE_ADMIN_ROLE_SUCCESS,
    payload: { adminId, newRole },
});

export const changeAdminRoleFailure = (error) => ({
    type: actionTypes.CHANGE_ADMIN_ROLE_FAILURE,
    payload: { error },
});




export const sendNotificationRequest = (notificationData) => ({
    type: actionTypes.SEND_NOTIFICATION_REQUEST,
    payload: notificationData,
});

export const sendNotificationSuccess = () => ({
    type: actionTypes.SEND_NOTIFICATION_SUCCESS,
});

export const sendNotificationFailure = (error) => ({
    type: actionTypes.SEND_NOTIFICATION_FAILURE,
    payload: { error },
});




export const uploadBannerRequest = (file) => ({
  type: actionTypes.UPLOAD_BANNER_REQUEST,
  payload: file,
});


export const fetchDataRequest = () => ({
  type: actionTypes.FETCH_DATA_REQUEST,
});
export const fetchDataSuccess = (bannerLinks) => ({
  type: actionTypes.FETCH_DATA_SUCCESS,
  payload: bannerLinks,
});
export const fetchDataFailure = (error) => ({
  type: actionTypes.FETCH_DATA_FAILURE,
  payload: error,
});


// Action creators for deleting
export const deleteDataRequest = (file) => ({
  type: actionTypes.DELETE_DATA_REQUEST,
  payload: file,
});
export const deleteDataSuccess = (id) => ({
  type: actionTypes.DELETE_DATA_SUCCESS,
  payload: id,
});
export const deleteDataFailure = (error) => ({
  type: actionTypes.DELETE_DATA_FAILURE,
  payload: error,
});



export const createSuperAdminRequest = (payload) => ({
  type: actionTypes.CREATE_SUPERADMIN_REQUEST,
  payload,
});

export const createSuperAdminSuccess = (payload) => ({
  type: actionTypes.CREATE_SUPERADMIN_SUCCESS,
  payload,
});

export const createSuperAdminFailure = (error) => ({
  type: actionTypes.CREATE_SUPERADMIN_FAILURE,
  error,
});

export const deleteAdminRequest = (adminId) => ({
  type: actionTypes.DELETE_ADMIN_REQUEST,
  payload: adminId,
});

export const deleteAdminSuccess = (adminId) => ({
  type: actionTypes.DELETE_ADMIN_SUCCESS,
  payload: adminId,
});

export const deleteAdminFailure = (error) => ({
  type: actionTypes.DELETE_ADMIN_FAILURE,
  payload: error,
});