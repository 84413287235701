import React, { useState } from "react"
import {
  Modal,
  Button,
  Form,
  CardTitle,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { updateUserInfoRequest } from "store/actions"

const UserModal = ({ user, closeModal, handleUpdateSuccess }) => {
  const [editedUser, setEditedUser] = useState(user)
  const [validationErrors, setValidationErrors] = useState({})
  const [img, setImg] = useState(user.profile_img_url)

  if(!user){  
    return null;
  }
  const dispatch = useDispatch()

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditedUser({ ...editedUser, [name]: value })
  }


  

  const handleSubmit = () => {
    const validationErrors = validateFormFields(editedUser)
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors)
      return
    }

    dispatch(updateUserInfoRequest(editedUser))
    handleUpdateSuccess()
    closeModal()
  }

  const validateFormFields = user => {
    const errors = {}
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (!emailRegex.test(user.email)) {
      errors.email = "البريد الإلكتروني غير صالح"
    }

    return errors
  }

  const closeButton = () =>{

    closeModal()
  }
 
  return (
    <Modal
      isOpen={true}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
     
    >
      <ModalHeader >
        <CardTitle>تعديل بيانات الشخصية</CardTitle>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup >
            <Label> اسم المستخدم </Label>
            <Input
              type="text"
              name="full_name"
              value={editedUser.full_name}
              onChange={handleInputChange}
            />
          </FormGroup>
         
         
        
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={closeButton}>
          إلغاء
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          حفظ التغييرات
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UserModal
