import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup } from "reactstrap";
import * as actions from '../../store/actions';
import { toast } from "react-toastify";

const CommissionSettings = ({ commission }) => {
  if(!commission) {
    return null;
  }
  
  const dispatch = useDispatch();
  const [newRate, setNewRate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isNaN(newRate) && newRate !== "") {
      const commissionRate = parseFloat(newRate);
      if (commissionRate >= 0 && commissionRate <= 100) {
        dispatch(actions.updateCommissionRate({ newRate: commissionRate, id: commission.$id }));
      } else {
        toast.error("معدل العمولة يجب أن يكون بين 0 و 100.");
      }
    } else {
      toast.error(" معدل العمولة غير صالح.");
    }
  };

  return (
    <Col xl={3}>
      <p>إعدادات معدل العمولة</p>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">معدل العمولة : %{commission.commission_rates}</CardTitle>
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Input
                type="text"
                id="newRate"
                placeholder="أدخل معدل العمولة الجديد"
                value={newRate}
                onChange={(e) => setNewRate(e.target.value)}
              />
            </div>
            <Button type="submit" color="primary">
              حفظ
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CommissionSettings;
