import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import moment from "moment";
import AccountStatus from "./AccountStatus";
import { useDispatch } from "react-redux";
import * as action from '../../store/actions'
const ProviderModal = (props) => {
  const { isOpen, toggle, providerData } = props; // Destructure providerData from props
  if (!providerData) {
    return null; // Return null if no order data is available
  }

  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const dispatch = useDispatch()
  const toggleViewStatusModal = (accountId, status) => {
    setSelectedAccountId(accountId);
    setSelectedStatus(status);
    setStatusModalOpen(!statusModalOpen);
  };

  const toggleStatusModal = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  const updateAccountStatus = (accountId, status) => {
    dispatch(action.updateAccountStatusRequest(accountId, status));
    toggleStatusModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تفاصيل الحساب</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            <strong>تاريخ التسجيل:</strong>{" "}
            <span className="text-primary">
              {moment(providerData.booking_execute_date).format("DD MMMM, YYYY")}
            </span>
          </p>
          <p className="mb-2 ">
            <strong className="m-3"> حالة الحساب:</strong>
            <Button className={getStatusButtonClass(providerData.account_status)}>
              {translateStatus(providerData.account_status)}
            </Button>
          </p>


          <hr />
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <strong>اسم الثلاثي:</strong>
                  </td>
                  <td> {providerData.full_name}</td>
                </tr>


                <tr>
                  <td>
                    <strong> حساب البنكي:</strong> </td>
                  <td>{providerData.bank_account} </td>

                </tr>

                <tr>
                  <td>
                    <strong>  بريد إلكتروني:</strong>
                  </td>
                  <td>{providerData.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>  الهاتف :</strong>
                  </td >
                  <td style={{ direction: "ltr" }}>{providerData.mobile}</td>
                </tr>
                <tr>
                  <td>
                    <strong>  عنوان الفرع:</strong>
                  </td>
                  <td>{providerData.address}</td>
                </tr>
                <tr>
                  <td>
                    <strong> معرف العميل:</strong>
                  </td>
                  <td>{providerData.$id}</td>
                </tr>
                <tr>
                  <td>
                    <strong> صالون:</strong>
                  </td>
                  <td>{providerData.salon_name}</td>
                </tr>
              </tbody>
              <AccountStatus
                isOpen={statusModalOpen}
                toggle={toggleViewStatusModal}
                accountId={selectedAccountId}
                status={selectedStatus}
                updateAccountStatus={updateAccountStatus}
              />
            </Table>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            إغلاق
          </Button>
          <Button
              className="btn btn-primary  "
              onClick={() =>
                toggleViewStatusModal(providerData.$id, providerData.account_status)
              }
            >
              تغير حالة الحساب
            </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

ProviderModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  providerData: PropTypes.object,
};

const translateStatus = status => {
  const translations = {
    active: "نشط",
    paused_for_subscription_expiry: "انتهاء صلاحية الاشتراك",
    action_required: "غير نشط",
    // Add more translations as needed
  }
  return translations[status] || status
}
const getStatusButtonClass = (accountStatus) => {
  switch (accountStatus) {
    case "active":
      return "btn-success";
    case "paused_for_subscription_expiry":
      return "btn-danger";
    case "action_required":
      return "btn-warning";
    default:
      return "btn-secondary";
  }
};

export default ProviderModal;
