import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import DOMPurify from "dompurify" // Import DOMPurify for input sanitization
import { toast } from "react-toastify"

const StatusModal = ({
  isOpen,
  toggle,
  status,
  updateOrderStatus,
  orderId,
}) => {
  const [newStatus, setNewStatus] = useState("")

  const allowedStatusOptions = [
    "pending",
    "approved",
    "rejected",
    "completed",
  ]

  const handleStatusChange = event => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value)
    setNewStatus(sanitizedValue)
  }

  const handleConfirmStatusUpdate = () => {
    if (isValidStatus(newStatus)) {
      updateOrderStatus(orderId, newStatus)
      toggle()
      setNewStatus("") // Reset the status after updating
    } else {
      toast.info("الرجاء اختيار حالة ")
    }
  }

  const isValidStatus = status => {
    return newStatus !== "" && allowedStatusOptions.includes(status)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تحديث حالة الطلب</ModalHeader>
        <ModalBody>
          <p>الحالة الحالية: {translateStatus(status)}</p>
          <div className="form-group">
            <label>اختر حالة جديدة:</label>
            <select
              className="form-control"
              value={newStatus}
              onChange={handleStatusChange}
            >
              <option disabled value=""> اختر حالة</option>
              {allowedStatusOptions.map(option => (
                <option key={option} value={option}>
                  {translateStatus(option)}
                </option>
              ))}
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmStatusUpdate}>
            تحديث الحالة
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            إلغاء
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

StatusModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  handleStatusUpdate: PropTypes.func,
}

export default StatusModal

const translateStatus = status => {
  const translations = {
    pending: " الانتظار",
    approved: " الموافقة عليه",
    rejected: " الرفض",
    completed: "تم الاكتمال",
  }

  return translations[status] || status
}
