// reducer.js
import {
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
} from "./actionTypes"

const initialState = {
  orders: [],
  loading: false,
  error: null,
  totalCompletedOrders: 0, // Initialize total completed orders to 0
  totalPendingOrders: 0, // Initialize total pending orders to 0
  totalRejectedOrders: 0,
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload, // Update orders
        totalCompletedOrders: action.payload.filter(
          order => order.booking_status === "completed"
        ).length, // Calculate total completed orders

        totalPendingOrders: action.payload.filter(
          order => order.booking_status === "pending"
        ).length, // Calculate total pending orders
        loading: false,
        error: null,

        totalRejectedOrders: action.payload.filter(
          order => order.booking_status === "rejected"
        ).length, // Calculate total rejected orders
        loading: false,
        error: null,
      
      }

    

      
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        orders: [],
        loading: false,
        error: action.payload,
      }
    case UPDATE_ORDER_STATUS_SUCCESS:
      const updatedOrder = action.payload // Assuming the payload contains the updated order
      const updatedOrders = state.orders.map(order =>
        order.$id === updatedOrder.$id ? updatedOrder : order
      )

      return {
        ...state,
        orders: updatedOrders,
        totalCompletedOrders:
          action.payload.booking_status === "completed"
            ? state.totalCompletedOrders + 1
            : state.totalCompletedOrders,
        totalPendingOrders:
          action.payload.booking_status === "completed"
            ? state.totalPendingOrders - 1
            : state.totalPendingOrders,
      }
    default:
      return state
  }
}

export default ordersReducer
