// appwriteConfig.js

const appwritPointes = {
  endpoint: process.env.REACT_APP_APPWRITE_ENDPOINT,
  project: process.env.REACT_APP_APPWRITE_PROJECT_ID,
  databaseID: process.env.REACT_APP_APPWRITE_DATABASE_ID,
  categoriseID: process.env.REACT_APP_APPWRITE_CATEGORISE_ID,
  invoicesID: process.env.REACT_APP_APPWRITE_SERVICE_PROVIDER_SALE_INVOICES_ID,
  notificationID:process.env.REACT_APP_APPWRITE_SERVICE_PROVIDERS_NOTIFICATIONS_ID,
  ratingsID: process.env.REACT_APP_APPWRITE_CUSTOMER_RATINGS_ID,
  servicesID: process.env.REACT_APP_APPWRITE_SERVICE_PROVIDER_SERVICES_ID,
  ordersID: process.env.REACT_APP_APPWRITE_ORDERS_ID,
  bucketID: process.env.REACT_APP_APPWRITE_BUCKET_ID,
  types: process.env.REACT_APP_APPWRITE_SERVICE_TYPE_ID,
  providers: process.env.REACT_APP_APPWRITE_PROVIDERS_ID,
  joinRequest: process.env.REACT_APP_APPWRITE_JOIN_REQUEST,
  superAdmin: process.env.REACT_APP_APPWRITE_SUPER_ADMIN,
  appUsers: process.env.REACT_APP_APPWRITE_APP_USERS,
  catgeoriBucketID: process.env.REACT_APP_APPWRITE_BUCKET_CATEGORY_ID,
  generalSettings: process.env.REACT_APP_APPWRITE_GENERAL_PLATFORM_SETTINGS,
  appnotificationID: process.env.REACT_APP_APPWRITE_APP_NOTIFICATION,
  
  bannerbucketID: process.env.REACT_APP_APPWRITE_APP_BANNERS_BUCKET,
  bannerslinksID: process.env.REACT_APP_APPWRITE_BANNER_LINKS_ID,
 
  app_id: process.env.REACT_APP_SMS_ID,
  app_sec: process.env.REACT_APP_SMS_SEC

  
  
  
  

  
  // Add other configurations as needed
}

export default appwritPointes
