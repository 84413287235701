// reducers.js

import * as actionTypes from './actionTypes';
  
  const initialState = {
    maintenanceMode: false,
    commissionRates: 0.0,
    plans: 0,
    admins: [],
    loading: false,
    error: null,
    uploading: false,
    bannerLinks: [],

    // Add other settings with initial values
  };
  
  const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_SETTINGS_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.UPDATE_SETTINGS_SUCCESS:
        return state;
        case actionTypes.FETCH_ADMINS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_ADMINS_SUCCESS:
      return { ...state, loading: false, admins: action.payload };
    case actionTypes.FETCH_ADMINS_FAILURE:
      return { ...state, loading: false, error: action.payload };



      case actionTypes.FETCH_DATA_REQUEST:
        return { ...state, loading: true, error: null };
      case actionTypes.FETCH_DATA_SUCCESS:
        return { ...state, loading: false, bannerLinks: action.payload, error: null };
      case actionTypes.FETCH_DATA_FAILURE:
        return { ...state, loading: false, error: action.payload };





      // Add other cases for different ActionTypes
      default:
        return state;
    }
  };
  
  export default settingsReducer;
  