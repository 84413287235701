import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const AdminRow = ({ admin }) => {
    if (!admin) {
        return null;
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const confirmDelete = (adminId) => {
        const userId = Cookies.get("sessionId", {
            secure: true, sameSite: 'None',
            path: "/"
        });
        if (adminId === userId) {
            toast.error('لا يمكن حذف حساب المستخدم الحالي.');
            return;
        }
        toast.warn(
            <div className='d-flex flex-column justify-content-center gap-2 align-items-center '>
                هل أنت متأكد أنك تريد حذف هذا المدير؟
                <Button color="danger" onClick={() => handleDelete(adminId)}>نعم</Button>
                <Button variant="secondary" onClick={() => toast.dismiss()}>لا</Button>
            </div>,
            {
                position: "top-center",
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            }
        );
    };

    const handleRoleChange = (newRole) => {
        setIsLoading(true); // Start loading
        dispatch(actions.changeAdminRoleRequest(admin.$id, newRole));

        setTimeout(() => {
            toast.success('تم تغيير الدور بنجاح');
            setIsLoading(false); // Stop loading after 20 seconds
        }, 20000); // 20 seconds
    };

    const handleDelete = (adminId) => {
        toast.dismiss();
        dispatch(actions.deleteAdminRequest(adminId));
    };

    return (
        <tr>
            <td>{admin.username}</td>
            <td>{admin.email}</td>
            <td>{admin.role}</td>
            <td>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle caret color="secondary">
                        {isLoading ? <Spinner size="sm" /> : 'تغيير الدور'}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => handleRoleChange('admin')}>مشرف عادي</DropdownItem>
                        <DropdownItem onClick={() => handleRoleChange('super_admin')}>مشرف عام</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </td>
            {/* <td>
                <Button color="danger" onClick={() => confirmDelete(admin.$id)}>حذف</Button>
            </td> */}
            <td>
                <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                    {showPassword ? admin.password : '••••••••'}
                    {showPassword ?
                        <i className="bi bi-eye-slash ms-2"></i> :
                        <i className="bi bi-eye-fill ms-2"></i>
                    }
                </span>
            </td>
        </tr>
    );
};

export default AdminRow;
