import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { logoutUser } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, Spinner } from "reactstrap";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(true);

  useEffect(() => {
    const logout = async () => {
      try {
        // Dispatch the logoutUser action
        dispatch(logoutUser({ history }));
        setTimeout(() => {
          history("/login"); // Redirect to login after successful logout
        }, 2000); // Simulate a delay for the logout process
      } catch (error) {
        throw error;
      }
    };

    logout(); // Call the logout function
  }, [dispatch, history]);

  const styles = {
    blurBackground: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backdropFilter: "blur(5px)",
      zIndex: 9998, // Ensure it covers the entire screen but below the modal
    },
    modalBody: {
      textAlign: "center",
    },
    spinner: {
      width: "3rem",
      height: "3rem",
    },
  };

  return (
    <div>
      <div style={styles.blurBackground}></div>
      <Modal isOpen={isLoggingOut} centered style={{ zIndex: 9999 }}>
        <ModalBody style={styles.modalBody}>
          <Spinner style={styles.spinner} />
        </ModalBody>
      </Modal>
    </div>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;