// saga.js

import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes"
import { databases, storage } from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { ID } from "appwrite"


async function fetchSetting() {
  const result = await databases.listDocuments(
    appwritPointes.databaseID,
    appwritPointes.generalSettings
  )
  return result.documents[0]
}
async function updateSetting() {
}
async function fetchAdmins() {
  try{
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin
    )
    return result.documents
  } catch (error) {
    throw error
  }
}
function* fetchAdminsSaga() {
  try {
    const admins = yield call(fetchAdmins); // Replace with your API call
    yield put(actions.fetchAdminsSuccess(admins));
  } catch (error) {
    yield put(actions.fetchAdminsFailure(error));
    throw error;
  }
}
 const changeAdminRoleApi = async (adminId, newRole) => {
  try{
    const result = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      adminId,
      {
        role: newRole,
      }
    );
    return result;
  } catch (error) {
    throw error;
  }
};
function* changeAdminRoleSaga(action) {
  try {
      const { adminId, newRole } = action.payload;
      // Call the API or service to change the admin role
      yield call(changeAdminRoleApi, adminId, newRole);
      const admins = yield call(fetchAdmins); // Replace with your API call
      yield put(actions.fetchAdminsSuccess(admins));
  } catch (error) {
      throw error;
  }
}

// Batch processing function
const batchCreateDocuments = async (databaseID, collectionID, documents) => {
  const results = [];
  const batchSize = 1; // Define a batch size that suits your application

  for (let i = 0; i < documents.length; i += batchSize) {
    const batch = documents.slice(i, i + batchSize);
    const batchResults = await Promise.all(
      batch.map(doc => databases.createDocument(databaseID, collectionID, ID.unique(), doc))
    );
    results.push(...batchResults);
  }

  return results;
};

export const sendNotificationAppUsers = async (notificationData, targetIds) => {
  try {
    const documents = targetIds.map(id => ({
      title: notificationData.title,
      content: notificationData.content,
      created_at: new Date().toISOString(),
      notification_type: notificationData.notification_type,
      isSeen: false,
      appUser: id
    }));

    return await batchCreateDocuments(appwritPointes.databaseID, appwritPointes.appnotificationID, documents);
  } catch (error) {
    throw error;
  }
};

export const sendNotificationServiceProvider = async (notificationData, targetIds) => {
  try {
    const documents = targetIds.map(id => ({
      title: notificationData.title,
      content: notificationData.content,
      created_at: new Date().toISOString(),
      notification_type: notificationData.notification_type,
      seen: false,
      serviceProviders: id
    }));
    return await batchCreateDocuments(appwritPointes.databaseID, appwritPointes.notificationID, documents);
  } catch (error) {
    throw error;
  }
};

function* sendNotificationSaga(action) {
  try {
    const { notificationData, targetIds, notificationTarget } = action.payload;

    if (notificationTarget === 'serviceProviders') {
      yield call(sendNotificationServiceProvider, notificationData, targetIds);
    } else if (notificationTarget === 'appUsers') {
     yield call(sendNotificationAppUsers, notificationData, targetIds);
    }
  } catch (error) {
    throw error;
  }
}

async function uploadBannerApi(formData) {
  try {
    const img = formData
    const imgUploaded = await uploadImage(img, appwritPointes.bannerbucketID)
    const imgUrl = imgUploaded.$id
      ? storage.getFileView(appwritPointes.bannerbucketID, imgUploaded.$id).href
      : ""

      const bannerData = {  
        image_url: imgUrl,
        img_id: imgUploaded.$id
      }
        await databases.createDocument(
        appwritPointes.databaseID,
        appwritPointes.bannerslinksID,
        ID.unique(),
        bannerData
      )
      
  } catch (error) {
    throw error;
}
}
async function uploadImage(img, bucket) {
  try {
    return await storage.createFile(bucket, ID.unique(), img)
  } catch (error) {
    throw error
  }
}
function* uploadBannerSaga(action) {
  try {
    const formData = action.payload;
     yield call(uploadBannerApi, formData);
     
  } catch (error) {
    console.log('Failed to upload banner:', error);
  }
}
async function fetchBannerLinks(){
  try {
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.bannerslinksID,
    )
    return result.documents;
  } catch(error){
    throw error;
  }
}
async function deleteDataApi(data){
  try {
    const  {url, img_id, documentId } = data
    const resutl = await databases.deleteDocument(
      appwritPointes.databaseID,
      appwritPointes.bannerslinksID,
      documentId,
    )
    if (img_id) {
      await storage.deleteFile(appwritPointes.bannerbucketID, img_id)
    }
    return resutl
  } catch(error){
    throw error
  }
}
function* fetchDataSaga() {
  try {
    const response = yield call(fetchBannerLinks); 
    yield put(actions.fetchDataSuccess(response));
  } catch (error) {
    throw error;
  }
}
function* deleteDataSaga(action) {
  try {
    const data =  yield call(deleteDataApi, action.payload); 
    yield put(actions.fetchDataRequest());
  } catch (error) {
    throw error
  }
}
async function UpdateCoRate(data) {
  const { id, newRate } = data
  await databases.updateDocument(
    appwritPointes.databaseID,
    appwritPointes.generalSettings,
    id,
    {
      commission_rates: newRate
    }
  )
}
async function aboutUs(data){
  const { $id, editedText} = data
  await databases.updateDocument(
    appwritPointes.databaseID,
    appwritPointes.generalSettings,
    $id,
    {
      about_us: editedText
    }
  )


}
async function changeMoode(data){
  const { $id, newMode} = data
  await databases.updateDocument(
    appwritPointes.databaseID,
    appwritPointes.generalSettings,
    $id,
    {
      maintenance_mode: newMode
    }
  )

}
function* fetchSettings() {
  try {
    const settings = yield call(fetchSetting); // Replace with your API call
    yield put(actions.fetchSettingsSuccess(settings));
  } catch (error) {
    yield put(actions.fetchSettingsFailure(error));
  }
}
function* updateSettings(action) {
  try {
    yield call(updateSetting, action.payload); // Replace with your API call
    yield put(actions.updateSettingsSuccess());
  } catch (error) {
    yield put(actions.updateSettingsFailure(error));
  }
}
function* updateCommision(action) {

  try {
    yield call(UpdateCoRate, action.payload); // Replace with your API call
    yield put(actions.fetchSettingsRequest());
  } catch (error) {
    throw error
  }
}
function* updateAboutUs(action){
  try {
    yield call(aboutUs, action.payload); // Replace with your API call
    yield put(actions.fetchSettingsRequest());
  } catch (error) {
    throw error
  }
}
function* maintenanceMood(action){
  try {
    yield call(changeMoode, action.payload); // Replace with your API call
    yield put(actions.fetchSettingsRequest());
  } catch (error) {
    throw error
  }
}
function* createSuperAdminSaga(action) {
  try {
    yield call(createSuperAdminApi, action.payload);
    yield put(actions.fetchAdminsRequest());
  } catch (error) {
    throw error;
  }
}

async function createSuperAdminApi(data) {
  try {
    const response = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      ID.unique(),
      data
    );  
    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteAdminApi(adminId) {  
  try {
    const result = await databases.deleteDocument(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      adminId,

    );
    return result;
  } catch (error) {
   
    throw error;
  }
}

function* deleteAdminSaga(action) {
  try {
      yield call(deleteAdminApi, action.payload); // Call the API
      yield put(actions.fetchAdminsRequest());
  } catch (error) {
    throw error;
  }
}




function* settingsSaga() {
  yield takeLatest(actionTypes.CHANGE_ADMIN_ROLE_REQUEST, changeAdminRoleSaga);
  yield takeLatest(actionTypes.FETCH_SETTINGS_REQUEST, fetchSettings);
  yield takeLatest(actionTypes.UPDATE_SETTINGS_REQUEST, updateSettings);
  yield takeLatest(actionTypes.UPDATE_COMMISSION_RATE, updateCommision);
  yield takeLatest(actionTypes.UPDATE_ABOUT_US, updateAboutUs);
  yield takeLatest(actionTypes.CHANGE_MAINANCE_MOODE, maintenanceMood);
  yield takeLatest(actionTypes.FETCH_ADMINS_REQUEST, fetchAdminsSaga);
  yield takeLatest(actionTypes.SEND_NOTIFICATION_REQUEST, sendNotificationSaga);
  yield takeLatest(actionTypes.UPLOAD_BANNER_REQUEST, uploadBannerSaga);
  yield takeLatest(actionTypes.FETCH_DATA_REQUEST, fetchDataSaga);
  yield takeLatest(actionTypes.DELETE_DATA_REQUEST, deleteDataSaga);
  yield takeLatest(actionTypes.CREATE_SUPERADMIN_REQUEST, createSuperAdminSaga);
  yield takeLatest(actionTypes.DELETE_ADMIN_REQUEST, deleteAdminSaga);
}

export default settingsSaga;
