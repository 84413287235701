// newPage.js
import React, { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import debounce from "lodash.debounce";
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import * as action from "../../store/actions"
import ServiceList from "./ServiceList"
import AddServiceForm from "./AddServiceForm"
import EditServiceForm from "./EditServiceForm"
import AddCategory from "./AddCategory";
const NewPage = () => {
  document.title = "ادارة الخدمات | DBsaloni Admin & Dashboard "
  const dispatch = useDispatch()
  const services = useSelector(state => state.service.services)
  const selectedService = useSelector(state => state.service.selectedService)
  const editFormRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    dispatch(action.fetchServicesRequest());
  }, [dispatch]);


    useEffect(() => {
      if (shouldScroll && editFormRef.current) {
        scrollToEditForm();
        setShouldScroll(false); // Reset the flag after scrolling
      }
    }, [shouldScroll]);
    // Function to scroll to the EditServiceForm component
    const scrollToEditForm = () => {
      if (editFormRef.current) {
        editFormRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

  const handleAddCategories = (category) => {
    dispatch(action.addCategory(category))
  }
  const handleRemoveCategory = (data) => {
    toast(
      <div>
        <p>سيتم حذف قسم <b>{data.title}</b>، هل أنت متأكد أنك تريد المتابعة؟</p>
        <Button
          className="btn-primary m-3"
          onClick={() => {
            dispatch(action.removeCategory(data))
            toast.dismiss()
          }}
        >
          مواصلة
        </Button>
        <Button
          className="btn-primary"
          onClick={() => {
            toast.dismiss()
          }}
        >
          الغاء
        </Button>
      </div>
    )
  }


  const handleAddService = (newService) => {
    dispatch(action.addService(newService)); // Pass newService instead of updatedService
  }

  const handleUpdateService = debounce((updatedService) => {
    dispatch(action.updateService(updatedService));
  }, 500);

  const handleRemoveService = serviceData => {
    toast(
      <div>
        <p>سيتم حذف خدمة <b>{serviceData.name}</b>، هل أنت متأكد أنك تريد المتابعة؟</p>
        <Button
          className="btn-primary m-3"
          onClick={() => {
            dispatch(action.removeService(serviceData.$id))
            toast.dismiss()
          }}
        >
          مواصلة
        </Button>
        <Button
          className="btn-primary"
          onClick={() => {
            toast.dismiss()
          }}
        >
          الغاء
        </Button>
      </div>
    )

  }

  

  return (
    <>
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs
            title=" اضافة خدمة جديدة "
            breadcrumbItem="ادارة الخدمات "
          />

          <h2> الخدمات</h2>
          <ServiceList
            services={services}
            handleEditService={(service) => {
              dispatch(action.setSelectedService(service));
              setShouldScroll(true); // Set the flag to scroll
            }}            handleRemoveService={handleRemoveService}
          />


          <h2>إضافة خدمة جديدة</h2>
          <AddServiceForm handleAddService={handleAddService} />

          <h2>إضافة  قسم</h2>
          <AddCategory
            handleAddCategories={handleAddCategories}
            handleRemoveCategory={handleRemoveCategory}
          />


          {selectedService && (
            <div ref={editFormRef}>
              <h2>تعديل الخدمة</h2>
              <EditServiceForm
                selectedService={selectedService}
                handleUpdateService={handleUpdateService}
              />
            </div>
          )}



        </Container>
      </div>
    </>
  )
}

export default NewPage
