// src/firebaseMessaging.js
import { onMessage } from 'firebase/messaging';
import { messaging } from './helpers/firebase/firebaseConfig';
import { toast } from 'react-toastify';

const initializeFirebaseMessaging = () => {
  onMessage(messaging, (payload) => {
    toast.success(`${payload.notification.title}: ${payload.notification.body}`);
  });
};

export default initializeFirebaseMessaging;



