import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import DOMPurify from "dompurify" // Import DOMPurify for input sanitization
import { toast } from "react-toastify"

const AccountStatus = ({
  isOpen,
  toggle,
  status,
  updateAccountStatus,
  accountId,
}) => {
  const [newStatus, setNewStatus] = useState("")
  const [isDisabled, setIsDisabled] = useState(false);

  const allowedStatusOptions = [
    "active",
    "paused_for_subscription_expiry",
    "action_required",

  ]

  const handleConfirmStatusUpdate = () => {
    if (isValidStatus(newStatus)) {
      setIsDisabled(true); // Disable the button
      updateAccountStatus(accountId, newStatus)
      toggle();
      setTimeout(() => setIsDisabled(false), 20000); // Enable button after 30 seconds
    }
  }


  const handleStatusChange = event => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value)
    setNewStatus(sanitizedValue)
  }



  const isValidStatus = status => {
    return newStatus !== "" && allowedStatusOptions.includes(status)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تحديث حالة الطلب</ModalHeader>
        <ModalBody>
          <p>الحالة الحالية: {translateStatus(status)}</p>
          <div className="form-group">
            <label>اختر حالة جديدة:</label>
            <select
              className="form-control"
              value={newStatus}
              onChange={handleStatusChange}
            >
              <option value=""> اختر حالة</option> {/* Add an empty option */}
              {allowedStatusOptions.map(option => (
                <option key={option} value={option}>
                  {translateStatus(option)}
                </option>
              ))}
            </select>

          </div>
        </ModalBody>
        <ModalFooter>

          <Button color="primary" disabled={isDisabled} onClick={handleConfirmStatusUpdate}>
            {isDisabled ? <span className="loading-icon">جاري التحديث...</span> : "تحديث الحالة"}
          </Button>{" "}

          <Button color="secondary" onClick={toggle}>
            إلغاء
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

AccountStatus.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  handleStatusUpdate: PropTypes.func,
}


const translateStatus = (status) => {

  const translations = {
    active: "التنشيط",
    paused_for_subscription_expiry: "لانتهاء صلاحية الاشتراك",
    action_required: " تعطيل",


  };

  return translations[status] || status;
};

export default AccountStatus
