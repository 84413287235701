import Cookies from "js-cookie"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as userActions from "./actions"
import {
  databases,
} from "../../../helpers/appwrite/appwriteConfig"
import { Query, } from "appwrite"
import appwritPointes from "helpers/appwrite/appwritePointes"
import { errorTranslations } from "./errorTranslations" // Import the translation mapping
import { toast } from "react-toastify"


function setCookieWithExpiration(name, value, days) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  Cookies.set(name, value, {
    expires: expirationDate, secure: true, sameSite: 'None',
    path: "/"
  });
}


// Function to remove a cookie
function removeCookie(name) {
  try {
    Cookies.remove(name, { secure: true, sameSite: 'None', path: "/" });
  } catch (error) {
    console.error("Error removing cookies:", error);
  }


}



async function loginUserApi(email, password) {


  try {
    const user = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("email", [email])]
    )
    if (user && password === user.documents[0].password) {
      return user.documents[0];
    } else {
      throw new Error("Invalid credentials");
    }


  } catch (error) {
    throw new Error("Invalid credentials");
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const res = yield call(loginUserApi, user.email, user.password)

    // Store user data in cookies with an expiration date (e.g., 7 days)
    setCookieWithExpiration("userId", res.$id, 1);
    setCookieWithExpiration("sessionId", res.$id, 1);
    // Dispatch login success action with user information
    yield put(userActions.loginSuccess(res))

    // Redirect to dashboard
    toast.success("تم تسجيل الدخول بنجاح")
    history("/dashboard")
  } catch (error) {

    toast.error("بيانات اعتماد غير صحيحة. يرجى التحقق من البريد الإلكتروني وكلمة المرور.")

  }
}

async function getUserProfile(userId) {
  try {
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("$id", [userId])]
    )
    const userProfile = {
      full_name: result.documents[0].username,
      email: result.documents[0].email,
      role: result.documents[0].role
      // Add other user information fields as needed
    }

    return userProfile
  } catch (error) {
    throw (error)
  }
}

async function getUserInfo(userId) {
  try {
    // Call your API endpoint to fetch user information
    const userProfile = await getUserProfile(userId)
    // Modify the response as needed and return user information
    return userProfile
  } catch (error) {
    throw error
  }
}

function* fetchUserInfoSaga(action) {

  try {
    const userInfo = yield call(getUserInfo, action.payload)

    yield put(userActions.storeUserInfo(userInfo))
  } catch (error) {
    // Handle error if needed
  }
}

// Worker saga to update order status
async function updateInfo(updatedData) {
  try {
    const userId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });
    const updatedFormData = {
      username: updatedData.full_name,
      // email: updatedData.email,

    }



    const response = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      userId,
      updatedFormData
    )

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

function* updateUser(action) {

  try {
    const updatedData = action.payload
    const updatedInfo = yield call(updateInfo, updatedData)
    yield put({ type: actionTypes.FETCH_USER_INFO, payload: updatedInfo.$id })
    yield put(userActions.updateUserInfoSuccess(updatedInfo))
  } catch (error) {
    console.error(error)
    // Handle error and dispatch an error action if needed
  }
}

// async function uploadImage(img) {
//   try {
//     return await storage.createFile(appwritPointes.bucketID, ID.unique(), img)
//   } catch (error) {
//     throw error
//   }
// }




async function logout() {
  try {
    const userId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });

    // Retrieve the token from local storage
    const storedToken = localStorage.getItem('firebaseMessagingToken');

    // Retrieve the existing tokens
    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("$id", [userId])]
    );


    if (res.documents[0]) {
      const existingTokensArray = res.documents[0].notification_token || [];

      // Remove the token from the array
      const updatedTokensArray = existingTokensArray.filter(token => token !== storedToken);

      // Update the document with the new array
      const responss = await databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.superAdmin,
        res.documents[0].$id,
        { notification_token: updatedTokensArray })

    }
  } catch (error) {
    throw error;
  }
}



function* logoutUser({ payload: { history } }) {

  try {

    yield call(logout)
    // Remove session cookie
    // removeCookie("userId");
    localStorage.removeItem('firebaseMessagingToken');
    localStorage.removeItem('firebaseMessagingTokenDispatched');
    removeCookie("sessionId");
    // Dispatch logout success action
    yield put(userActions.logoutUserSuccess())

    // Redirect to login
    history("/login")
  } catch (error) {
    yield put(userActions.apiError(error))
  }
}


async function changPass(data) {
  try {
    const { newPassword } = data.currentPassword;

    const userId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });


    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("$id", [userId])]
    );

    if (res && newPassword) {
      const newdata = await databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.superAdmin,
        res.documents[0].$id,
        { password: newPassword }
      );

      return (newdata.$id)
    }
  } catch (error) {
    throw new Error("  يرجى التحقق من عنوان بريدك الإلكتروني او كلمة المرور  ");
  }
}


function* changePassword(action) {
  try {
    // Call your API function to change the password
    const response = yield call(changPass, action.payload)
    // Dispatch a success action
    yield put(userActions.fetchUserInfo(response))
    yield put({ type: actionTypes.CHANGE_PASSWORD_SUCCESS, payload: response })
  } catch (error) {
    // Dispatch a failure action in case of an error
    yield put({ type: actionTypes.CHANGE_PASSWORD_FAILURE, error })
  }
}


async function updateEmail(data) {
  try {
    const userId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });
    const { newEmail, password } = data

    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("$id", [userId])]
    )
    if (res && res.documents[0].password === password) {

      const newdata = databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.superAdmin,
        res.documents[0].$id,
        { email: newEmail }
      )
      return newdata
    }
  } catch (error) {

    throw error;
  }
}

function* changeEmail(action) {

  try {

    // Call your API function to change the email
    const response = yield call(updateEmail, action.payload.newEmail)

    yield put({ type: actionTypes.FETCH_USER_INFO, payload: response.$id })
    // Dispatch a success action
    yield put({ type: actionTypes.CHANGE_EMAIL_SUCCESS })
  } catch (error) {
    // Translate the error message if a translation exists; otherwise, use the original message
    const translatedError = errorTranslations[error.message] || error.message
    // Dispatch a failure action with the translated error message
    yield put({
      type: actionTypes.CHANGE_EMAIL_FAILURE,
      error: translatedError,
    })
  }
}






async function SaveToken(token) {
  try {
    const userId = Cookies.get("sessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });

    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.superAdmin,
      [Query.equal("$id", [userId])]
    );

    if (res.documents[0]) {
      const existingTokensArray = res.documents[0].notification_token || [];

      // Check if the token already exists in the array
      if (!existingTokensArray.includes(token)) {
        const updatedTokensArray = [...existingTokensArray, token];

         await databases.updateDocument(
          appwritPointes.databaseID,
          appwritPointes.superAdmin,
          res.documents[0].$id,
          { notification_token: updatedTokensArray }
        );
      }
    }
  } catch (error) {
    throw error;
  }
}







function* saveUserToken(action) {
  try {
    const token = action.payload;
    // Save the token in cookies with an expiration date (e.g., 7 days)
    const response = yield call(SaveToken, token)
    // Optionally, dispatch a success action if needed
  } catch (error) {
    throw error;
  }
}





function* authSaga() {
  yield takeEvery(actionTypes.LOGIN_USER, loginUser)
  yield takeEvery(actionTypes.LOGOUT_USER, logoutUser)
  yield takeLatest(actionTypes.FETCH_USER_INFO, fetchUserInfoSaga)
  yield takeLatest(actionTypes.UPDATE_USER_INFO_REQUEST, updateUser)
  yield takeEvery(actionTypes.SAVE_USER_TOKEN, saveUserToken); // Add this line
  yield takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLatest(actionTypes.CHANGE_EMAIL_REQUEST, changeEmail)
}

export default authSaga
