import { useEffect,  } from "react";
import { useDispatch  } from "react-redux";
import client from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import * as providerActions from "../../store/actions"


const ServiceProviderSub = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.providers}.documents`,
      response => {
        if (
          response.events.includes(
            "databases.*.collections.*.documents.*.create"
          )
        ) {
          handleCreateEvent()
        } else if (
          response.events.includes(
            "databases.*.collections.*.documents.*.update"
          )
        ) {
          handleUpdateEvent()
        }
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])
  const handleCreateEvent = () => {
    dispatch(providerActions.fetchServiceProvidersRequest())
  }
  const handleUpdateEvent = ()=>{
    dispatch(providerActions.fetchServiceProvidersRequest())

  }

  return null; // This component doesn't render anything
};

export default ServiceProviderSub;
