// reducer.js

import * as actionTypes from './actionTypes';

const initialState = {
  serviceProviderRequests: [],
  serviceProviders: [],
  loading: false,
  poviderLoading: false,
  error: null,
};

const serviceProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_PROVIDERS_REQUEST:
    case actionTypes.ADD_SERVICE_PROVIDER_REQUEST:
    case actionTypes.REMOVE_SERVICE_PROVIDER_REQUEST:
    case actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_SERVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        serviceProviders: action.serviceProviders,
        loading: false,
        error: null,
      };

    case actionTypes.ADD_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        serviceProviders: [...state.serviceProviders, action.newServiceProvider],
        loading: false,
        error: null,
      };

    case actionTypes.REMOVE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        serviceProviders: state.serviceProviders.filter(
          (provider) => provider.$id !== action.serviceProviderId
        ),
        loading: false,
        error: null,
      };
    case actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_SUCCESS:
      return {
        ...state,
        serviceProviderRequests: action.requests, // Update the requests in the state
        loading: false,
        error: null,
      };

    case actionTypes.REJECT_SERVICE_PROVIDER_REQUEST:
      // Filter out the rejected request based on its ID
      const updatedRequests = state.serviceProviderRequests.filter(
        (request) => request.$id !== action.serviceProviderId.$id
      );
      return {
        ...state,
        serviceProviderRequests: updatedRequests,
      };

      case actionTypes.CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };

    case actionTypes.APPROVE_SERVICE_PROVIDER_FAILURE:
    case actionTypes.FETCH_SERVICE_PROVIDERS_FAILURE:
    case actionTypes.ADD_SERVICE_PROVIDER_FAILURE:
    case actionTypes.REMOVE_SERVICE_PROVIDER_FAILURE:
    case actionTypes.FETCH_SERVICE_PROVIDER_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default serviceProviderReducer;
