import * as actionTypes from "./actionTypes"

export const fetchClientsRequest = () => ({
  type: actionTypes.FETCH_CLIENTS_REQUEST,
});

export const fetchClientsSuccess = (clients) => ({
  type: actionTypes.FETCH_CLIENTS_SUCCESS,
  payload: clients,
});

export const fetchClientsFailure = (error) => ({
  type: actionTypes.FETCH_CLIENTS_FAILURE,
  payload: error,
});

export const updateClientStatusRequest = (clientId, newStatus) => ({
  type: actionTypes.UPDATE_CLIENT_STATUS_REQUEST,
  payload: { clientId, newStatus },
});

export const updateClientStatusSuccess = (clientId, newStatus) => ({
  type: actionTypes.UPDATE_CLIENT_STATUS_SUCCESS,
  payload: { clientId, newStatus },
});

export const updateClientStatusFailure = (error) => ({
  type: actionTypes.UPDATE_CLIENT_STATUS_FAILURE,
  payload: error,
});
