import React, { useEffect, useState, useMemo } from "react"
import { Button } from "reactstrap"
import * as ClientActions from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import ProviderModal from "./ProviderModal"
import TableContainer from "components/Common/TableContainer"
import { Name } from "./AccCol"
import client from "helpers/appwrite/appwriteConfig"
import appwritPointes from "helpers/appwrite/appwritePointes"

const ListClients = () => {
    const { clients } = useSelector(state => state.clients)
    const dispatch = useDispatch()
      const [selectedUser, setSelectedUser] = useState(null)
      const [editData, setEditData] = useState(null)
      const [modal1, setModal1] = useState(false)
      const [modal2, setModal2] = useState(false)

    useEffect(() => {
        dispatch(ClientActions.fetchClientsRequest())
    }, [])

      const handleEditProviderModal = service => {
        setEditData(service)
        setModal2(!modal2)
      }
      const toggleViewModal = userData => {
        setSelectedUser(userData)
        setModal1(!modal1)
      }

    const columns = useMemo(
        () => [
            {
         
                accessor: "email",
                disableFilters: false,
                filterable: true,
                Cell: cellProps => <Name {...cellProps} />,
            },
            {
                Header: "اسم المستخدم",
                accessor: "username",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => <Name {...cellProps} />,
            },
            {
                Header: "حالة الحساب ",
                accessor: "account_status",
                disableFilters: true,
                Cell: ({ row }) => (
                    translateStatus(row.original.account_status)
                ),
            },

            {

                accessor: "description",
                disableFilters: true,
                Cell: ({ row }) => (
                    <Button onClick={() => toggleViewModal(row.original)}>
                  معلومات إضافية
                    </Button>
                ),
            },
        ],
        []
    )
    useEffect(() => {
        const unsubscribe = client.subscribe(
            `databases.${appwritPointes.databaseID}.collections.${appwritPointes.appUsers}.documents`,
            response => {
                if (
                    response.events.includes(
                        "databases.*.collections.*.documents.*.create"
                    )
                ) {
                    handleCreateEvent()
                } else if (
                    response.events.includes(
                        "databases.*.collections.*.documents.*.update"
                    )
                ) {
                    handleUpdateEvent()
                }
            }
        )

        return () => {
            unsubscribe()
        }
    }, [])
      const handleCreateEvent = () => {
        dispatch(ClientActions.fetchClientsRequest())
      }
      const handleUpdateEvent = ()=>{
        dispatch(ClientActions.fetchClientsRequest())

      }

    return (
        <div>
            <TableContainer
                columns={columns}
                data={clients || []}
                isGlobalFilter={false}
                customPageSize={5}
                customPageSizeOptions={true}
            />
            <ProviderModal
        isOpen={modal1}
        toggle={toggleViewModal}
        userData={selectedUser}
      />


        </div>
    )
}



export default ListClients

const getStatusButtonClass = (accountStatus) => {
    switch (accountStatus) {
        case "active":
            return "btn-success";
   
        case "action_required":
            return "btn-warning";
        case "suspended_for_violation":
            return "btn-danger";
        default:
            return "btn-secondary";
    }
};

const translateStatus = status => {
    const translations = {
        active: "نشط",
        paused_for_subscription_expiry: "لانتهاء صلاحية الاشتراك",
        action_required: "غير نشط",
        suspended_for_violation: "مخالفة",
    }
    return translations[status] || status
}