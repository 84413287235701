// actionTypes.js

export const FETCH_SERVICE_PROVIDERS_REQUEST = 'FETCH_SERVICE_PROVIDERS_REQUEST';
export const FETCH_SERVICE_PROVIDERS_SUCCESS = 'FETCH_SERVICE_PROVIDERS_SUCCESS';
export const FETCH_SERVICE_PROVIDERS_FAILURE = 'FETCH_SERVICE_PROVIDERS_FAILURE';

export const ADD_SERVICE_PROVIDER_REQUEST = 'ADD_SERVICE_PROVIDER_REQUEST';
export const ADD_SERVICE_PROVIDER_SUCCESS = 'ADD_SERVICE_PROVIDER_SUCCESS';
export const ADD_SERVICE_PROVIDER_FAILURE = 'ADD_SERVICE_PROVIDER_FAILURE';

export const REMOVE_SERVICE_PROVIDER_REQUEST = 'REMOVE_SERVICE_PROVIDER_REQUEST';
export const REMOVE_SERVICE_PROVIDER_SUCCESS = 'REMOVE_SERVICE_PROVIDER_SUCCESS';
export const REMOVE_SERVICE_PROVIDER_FAILURE = 'REMOVE_SERVICE_PROVIDER_FAILURE';

// actionTypes.js

export const FETCH_SERVICE_PROVIDER_REQUESTS_REQUEST = 'FETCH_SERVICE_PROVIDER_REQUESTS_REQUEST';
export const FETCH_SERVICE_PROVIDER_REQUESTS_SUCCESS = 'FETCH_SERVICE_PROVIDER_REQUESTS_SUCCESS';
export const FETCH_SERVICE_PROVIDER_REQUESTS_FAILURE = 'FETCH_SERVICE_PROVIDER_REQUESTS_FAILURE';


// actionTypes.js

export const APPROVE_SERVICE_PROVIDER_REQUEST = 'APPROVE_SERVICE_PROVIDER_REQUEST';
export const APPROVE_SERVICE_PROVIDER_SUCCESS = 'APPROVE_SERVICE_PROVIDER_SUCCESS';
export const APPROVE_SERVICE_PROVIDER_FAILURE = 'APPROVE_SERVICE_PROVIDER_FAILURE';

export const REJECT_SERVICE_PROVIDER_REQUEST = 'REJECT_SERVICE_PROVIDER_REQUEST';
export const REJECT_SERVICE_PROVIDER_SUCCESS = 'REJECT_SERVICE_PROVIDER_SUCCESS';
export const REJECT_SERVICE_PROVIDER_FAILURE = 'REJECT_SERVICE_PROVIDER_FAILURE';

export const UPDATE_ACCOUNT_STATUS_REQUEST = 'UPDATE_ACCOUNT_STATUS_REQUEST';

export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'UPDATE_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_ACCOUNT_STATUS_FAILURE = 'UPDATE_ACCOUNT_STATUS_FAILURE';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const CLEAR_ERROR = 'CLEAR_ERROR';
