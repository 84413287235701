import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategoriesRequest } from "../../store/actions";

// Function to sanitize user input to prevent injection
const sanitizeInput = (input) => {
  return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
};

const AddCategory = ({ handleAddCategories, handleRemoveCategory }) => {
  const { categories } = useSelector((state) => state.service);
  const [activeTab, setactiveTab] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({ name: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesRequest());
  }, [dispatch, fetchCategoriesRequest]);

  const validateForm = () => {
    const newErrors = { name: "" };
    const sanitizedName = sanitizeInput(formData.name);

    if (sanitizedName.trim() === "") {
      newErrors.name = "اسم القسم مطلوب.";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const sanitizedName = sanitizeInput(formData.name);

      const newData = {
        title: sanitizedName,
      };
      handleAddCategories(newData);

      setFormData({
        name: "",
      });
      setactiveTab(1);
    }
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col lg="6">
            <Card>
              <CardBody>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink>
                          <span className="number">1</span>
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>

                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Form>
                          <FormGroup>
                            <Label for="category-name">اسم القسم</Label>
                            <Input
                              type="text"
                              id="category-name"
                              placeholder="أدخل اسم القسم"
                              value={formData.name}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{errors.name}</span>
                          </FormGroup>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </div>

                  <div className="actions clearfix">
                    <ul>
                      <li className="next">
                        <Link onClick={handleFormSubmit}>إرسال</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" style={{ height: "37vh", overflow: "scroll" }}>
            <Table>
              <thead>
                <tr>
                  <th>الأقسام</th>
                </tr>
              </thead>
              <tbody>
                {categories.slice().reverse().map((category) => (
                  <tr key={category.$id}>
                    <td> {category.title}</td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => handleRemoveCategory(category)}
                      >
                        حذف
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AddCategory;
