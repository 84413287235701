import axios from 'axios';
import appwritPointes from 'helpers/appwrite/appwritePointes';
import { toast } from "react-toastify";
import React from "react";

async function sms({ email, password, mobile }) {
  const url = "https://salons.dbsaloni.com/" 
  let message;

  message = ` بيانات الدخول\n${email}\n${password}\n${url}`;

  const app_hash = btoa(`${appwritPointes.app_id}:${appwritPointes.app_sec}`);
  const smsData = {
    "messages": [
      {
        "text": `${message}`,
        "numbers": [mobile],
        "sender": 'DBSALONI',
      },
    ],
  };


  try {
    const response = await axios.post('https://api-sms.4jawaly.com/api/v1/account/area/sms/send', smsData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${app_hash}`,
      },
    });

    const { status, data } = response;
    if (status === 200) {
      if (data.messages[0].err_text) {
        toast.error(data.messages[0].err_text);
      } else {
        toast.success("تم الارسال بنجاح");
      }
    } else if (status === 400) {
      toast.error(data.message);
    } else if (status === 422) {
      toast.error('نص الرسالة فارغ');
    } else {
      toast.error(`محظور بواسطة كلاودفلير. Status code: ${status}`);
    }
  } catch (error) {
    toast.error(`تعذر إرسال الرسائل القصيرة إلى هذا الرقم (${mobile}) البريد الإلكتروني (${email}) بسبب تنسيق رقم الهاتف أو خطأ في الشبكة ولكن يمكنك الاتصال به يدويًا.`); 
  
  }


}
export default sms;
