import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"

// Redux
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { fetchUserInfo } from "store/actions" // Import the fetchUserInfo action

// users

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const user = useSelector(state => state.Login.user) // Modify the selector as needed
  const [username, setusername] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      setusername(user.full_name)
    } else {
      const userId = Cookies.get("sessionId", {
        secure: true, sameSite: 'None',
        path: "/"
      });
      dispatch(fetchUserInfo(userId)) // Dispatch the fetchUserInfo action
    }
  }, [user, dispatch])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="dripicons-align-center" />{" "}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/acount-management">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            الملف الشخصي{" "}
          </DropdownItem>
       
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>تسجيل الخروج</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
