import React from 'react';
import { Container } from "reactstrap";
import ListClients from './ListClients';
import Breadcrumbs from "../../components/Common/Breadcrumb";
    const newPage = () => {
        //meta title
        document.title="إدارة العملاء | DBsaloni Admin & Dashboard ";
        return (
            <>
                <div className="page-content">
                    <Container fluid={false}>
                 
                        <Breadcrumbs title="إدارة العملاء" breadcrumbItem="العملاء" />
                        <ListClients />

                    </Container>
                </div>
            </>
        );
    }

export default newPage;