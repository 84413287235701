// src/RequestPermissionComponent.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestForToken } from './requestPermission';

const RequestPermissionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    requestForToken(dispatch);
  }, [dispatch]);

  return null; // This component does not render anything
};

export default RequestPermissionComponent;