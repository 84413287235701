export const actionTypes = {
  FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
};

export const fetchTransactions = () => ({
  type: actionTypes.FETCH_TRANSACTIONS,
});

export const setTransactions = data => ({
  type: actionTypes.SET_TRANSACTIONS,
  data,
});

export const confirmPayment = (documentId) => ({
  type: actionTypes.CONFIRM_PAYMENT,
  documentId,
});

