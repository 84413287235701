import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import moment from "moment";
import AccountStatus from "./AccountStatus";
import { useDispatch } from "react-redux";
import * as action from '../../store/actions'
const ProviderModal = (props) => {
  const { isOpen, toggle, userData } = props; // Destructure userData from props
  if (!userData) {
    return null; // Return null if no order data is available
  }
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const dispatch = useDispatch()
  const toggleViewStatusModal = (accountId, status) => {
    setSelectedAccountId(accountId);
    setSelectedStatus(status);
    setStatusModalOpen(!statusModalOpen);
  };

  const toggleStatusModal = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  const updateAccountStatus = (accountId, status) => {
    dispatch(action.updateClientStatusRequest(accountId, status));
    toggleStatusModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تفاصيل الحساب</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            <strong>تاريخ التسجيل:</strong>{" "}
            <span className="text-primary">
              {moment(userData.booking_execute_date).format("DD MMMM, YYYY")}
            </span>
          </p>
          <p className="mb-2 ">
            <strong className="m-3"> حالة الحساب:</strong>
            <Button className={getStatusButtonClass(userData.account_status)}>
              {translateStatus(userData.account_status)}
            </Button>
          </p>
          <hr />
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <strong>اسم الثلاثي:</strong>
                  </td>
                  <td> {userData.username}</td>
                </tr>
                <tr>
                  <td>
                    <strong>  بريد إلكتروني:</strong>
                  </td>
                  <td>{userData.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>  الهاتف :</strong>
                  </td>
                  <td style={{ direction: "ltr" }}>{userData.mobile}</td>

                </tr>

                <tr>
                  <td>
                    <strong> معرف العميل:</strong>
                  </td>
                  <td>{userData.$id}</td>
                </tr>

              </tbody>
              <AccountStatus
                isOpen={statusModalOpen}
                toggle={toggleViewStatusModal}
                accountId={selectedAccountId}
                status={selectedStatus}
                updateAccountStatus={updateAccountStatus}
              />
            </Table>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            إغلاق
          </Button>
          <Button
            className="btn btn-primary  "
            onClick={() =>
              toggleViewStatusModal(userData.$id, userData.account_status)
            }
          >
            تغير حالة الحساب
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

ProviderModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  userData: PropTypes.object,
};

const translateStatus = status => {
  const translations = {
    active: "نشط",
    action_required: "غير نشط",
    // Add more translations as needed
  }
  return translations[status] || status
}
const getStatusButtonClass = (accountStatus) => {
  switch (accountStatus) {
    case "active":
      return "btn-success";
    case "action_required":
      return "btn-warning";
    default:
      return "btn-secondary";
  }
};


export default ProviderModal;
