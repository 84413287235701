import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const ActivityComp = ({ services }) => {
  // Use slice to limit the number of services displayed to a maximum of 5
  const displayedServices = services.slice(0, 5);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5"> الخدمات</CardTitle>
          <ul className="verti-timeline list-unstyled">
            {displayedServices.map((service) => (
              <li className="event-list active" key={service.$id}>
                <div className="event-timeline-dot">
                  <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                </div>
                <div className="flex-shrink-0 d-flex">
                  <div className="me-3">
                    <h5 className="font-size-14">{service.name}</h5>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="text-center mt-4">
            <Link
              to="/servicesManagment"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              عرض المزيد <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivityComp;
