import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import moment from "moment";
import { ar } from 'date-fns/locale';
import { parseISO, format } from 'date-fns';

const OrdersModal = (props) => {
  const { isOpen, toggle, orderData } = props; // Destructure orderData from props
  if (!orderData || !orderData.booking_execute_date || !orderData.app_user_id) {
    return null; // Return null if no order data or booking_execute_date is available
  }

  const appUserId = orderData.app_user_id;
  const formattedDate = format(parseISO(orderData.booking_execute_date), 'MMMM dd, yyyy h:mm a', { locale: ar });

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تفاصيل الطلب</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            <strong> تاريخ إنشاء الطلب:</strong>{" "}
            <span className="text-primary">
              {moment(orderData.$createdAt).format("DD MMMM, YYYY")}
            </span>
          </p>
          <p className="mb-2">
            <strong> تاريخ تنفيذ الحجز:</strong>{" "}
            <span className="text-primary">
              {formattedDate}
            </span>
          </p>
          <p className="mb-2">
            <strong> عدد المستفيدين:</strong> {orderData.The_number_of_beneficiaries}
          </p>

          <p className="mb-2">
            <strong>الخدمة المطلوبة:</strong> {orderData.serviceProviderServices.name}
          </p>
          <p className="mb-2">
            <strong> قسم الخدمة:</strong> {orderData.category_type}
          </p>
          <p className="mb-2">
            <strong>التعليقات:</strong> {orderData.comment}
          </p>

          <hr />
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <strong>الإجمالي:</strong>
                  </td>
                  <td> {orderData.total} ريال </td>
                </tr>
                <tr>
                  <td>
                    <strong> عمولة الخدمة :</strong>
                  </td>
                  <td> {orderData.platform_commission}%</td>
                </tr>
                <tr>
                  <td>
                    <strong>الإجمالي بعد الخصم عمولة:</strong>
                  </td>
                  <td>{orderData.total_after_discount} ريال</td>
                </tr>

                <tr>
                  <td>
                    <strong>تكلفة الخدمة في المنزل:</strong>
                  </td>
                  <td> {orderData.at_home_service_cost} ريال</td>
                </tr>
                <tr>
                  <td>
                    <strong>طريقة الدفع:</strong>
                  </td>
                    <td>{orderData.paymentMethod ? orderData.paymentMethod : ' '}</td>    
                   </tr>
                <tr>
                  <td>
                    <strong>حالة الدفع:</strong>
                  </td>
                  <td>{translateStatus(orderData.paymentStatus)}</td>
                </tr>
                {appUserId && (
                  <>
                    <tr>
                      <td>
                        <strong> اسم مستفيد من الخدمة:</strong>
                      </td>
                      <td>{appUserId.username}</td>
                    </tr>
                    <tr>
                      <td >
                        <strong>   رقم الهاتف المستفيد من الخدمة:</strong>
                      </td>
                      <td style={{ direction: "ltr" }}> {appUserId.mobile}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td >
                    <strong>  مقدم الخدمة:</strong>
                  </td>
                  <td style={{ direction: "ltr" }}> {orderData.service_providers_id.full_name}</td>
                </tr>

                <tr>
                  <td >
                    <strong>   رقم الهاتف مقدم الخدمة :</strong>
                  </td>
                  <td style={{ direction: "ltr" }}> {orderData.service_providers_id.mobile}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            إغلاق
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

OrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  orderData: PropTypes.object,
};

export default OrdersModal;


const translateStatus = status => {
  const translations = {
    pending: "قيد الانتظار",
    paid: "تم الدفع ",
    pailed: "فشل دفع ",

  }
  return translations[status] || status

}



