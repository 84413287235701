
import * as actionTypes from './actionTypes';
  
  const initialState = {
    clients: [],
    loading: false,
    error: null,
  };
  
  const clientReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_CLIENTS_REQUEST:
        return { ...state, loading: true };
  
      case actionTypes.FETCH_CLIENTS_SUCCESS:
        return { ...state, clients: action.payload, loading: false };
  
      case actionTypes.FETCH_CLIENTS_FAILURE:
        return { ...state, error: action.payload, loading: false };
  
      case actionTypes.UPDATE_CLIENT_STATUS_SUCCESS:
        // Update the client status in the clients array
        const updatedClients = state.clients.map((client) =>
          client.$id === action.payload.clientId
            ? { ...client, status: action.payload.newStatus }
            : client
        );
        return { ...state, clients: updatedClients };
  
      default:
        return state;
    }
  };
  
  export default clientReducer;
  