import { useEffect } from "react";
import { useDispatch } from "react-redux";
import client from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { fetchOrdersRequest, fetchServiceProviderRequestsRequest, fetchTransactions } from "../../store/actions";
import { toast } from 'react-toastify';

const OrderSubscription = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Subscribe to events for the orders collection
    const unsubscribeOrders = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.ordersID}.documents`,
      (response) => {
        if (response.events.includes('databases.*.collections.*.documents.*.create')) {
          handleCreateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.update')) {
          // handleUpdateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.delete')) {
          // handleDeleteEvent(response);
        }
      }
    );

    // Subscribe to events for the joinRequest collection
    const unsubscribeJoinRequest = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.joinRequest}.documents`,
      (response) => {
        if (response.events.includes('databases.*.collections.*.documents.*.create')) {
          handleJoinRequestCreateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.update')) {
          // handleJoinRequestUpdateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.delete')) {
          // handleJoinRequestDeleteEvent(response);
        }
      }
    );

    const unsubscribeInvoices = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.invoicesID}.documents`,
      (response) => {
        if (response.events.includes('databases.*.collections.*.documents.*.create')) {
          handleInvoices(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.update')) {
          // handleJoinRequestUpdateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.delete')) {
          // handleJoinRequestDeleteEvent(response);
        }
      }
    );

    // Cleanup the subscriptions when the component unmounts
    return () => {
      unsubscribeOrders();
      unsubscribeJoinRequest();
      unsubscribeInvoices();
    };
  }, []);

  const handleCreateEvent = () => {
    dispatch(fetchOrdersRequest());
  };

  const handleJoinRequestCreateEvent = (response) => {
    // Handle the create event for joinRequest collection
    dispatch(fetchServiceProviderRequestsRequest());
  
    // Show a toast notification in Arabic
    toast.info('📢 تم تقديم طلب انضمام جديد! يرجى اتخاذ الإجراء اللازم.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  
    // Add any additional logic here
  };

  const handleInvoices = (response) => {
    dispatch(fetchTransactions());
  };

  return children;
};

export default OrderSubscription;