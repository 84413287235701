import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const OrderStatusChart = ({ data }) => {
  const chartData = {
    labels: ['الطلبات المكتملة', 'قيد المراجعة', 'الطلبات الملغاة'],
    datasets: [
      {
        data: data,
        backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
      },
    ],
  };

  return (
    <div>
      <Doughnut data={chartData} />
    </div>
  );
};

export default OrderStatusChart;
