import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import appwritPointes from "helpers/appwrite/appwritePointes"
import client from "helpers/appwrite/appwriteConfig"
import Cookies from "js-cookie"
import { fetchOrdersRequest } from "store/actions"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";


const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [modal, setModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const [menu, setMenu] = useState(false)
  const orders = useSelector(state => state.orders.orders)
  const pendingOrdersCount = orders.filter(
    order => order.booking_status === "pending"
  ).length

  const userId = Cookies.get("userId") // Replace with your user ID retrieval logic
  const dispatch = useDispatch()


  const sortedNotifications = orders.slice().sort((a, b) => new Date(b.$createdAt) - new Date(a.$createdAt));


  useEffect(() => {
    // Subscribe to events for the specified collection
    const unsubscribe = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.ordersID}.documents`,
      response => {
        if (
          response.events.includes(
            "databases.*.collections.*.documents.*.update"
          )
        ) {
          handleUpdateEvent()
        }
      }
    )

    // Cleanup the subscription when the component unmounts
    return () => {
      unsubscribe()
    }
  }, [userId])

  const handleUpdateEvent = () => {

    dispatch(fetchOrdersRequest())

  }

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setModal(true);
  };

  const toggleModal = () => {
    if (selectedNotification && !selectedNotification.seen) {
      setSelectedNotification(null);
    }
    setModal(!modal);
  };


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle className="btn header-item noti-icon position-relative" tag="button" id="page-header-notifications-dropdown">
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{pendingOrdersCount}</span>
        </DropdownToggle>


        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("إشعارارت")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small" onClick={() => setModal(true)}>
                  {props.t("View All")}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {orders
              .filter(order => order.booking_status === "pending")
              .map(order => (
                <div
                  className="text-reset notification-item"
                  key={order.$id} // Assuming each order has a unique ID
                  onClick={() => handleNotificationClick(order)}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {order.app_user_id?.username ?? 'N/A'}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{order.serviceProviderServices?.description ?? 'N/A'}<br /></p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />
                          {order.$createdAt
                            ? new Date(order.$createdAt).toLocaleString("ar-EG", {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })
                            : 'Invalid date'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedNotification?.title ?? props.t("إشعارارت")}</ModalHeader>
        <ModalBody>
          {selectedNotification ? (
            <div>
              <h6>{selectedNotification?.category_type ?? 'N/A'}</h6>
              <p>{selectedNotification?.serviceProviderServices?.name ?? 'N/A'}</p>
              <p className="text-muted">
                {selectedNotification?.serviceProviderServices?.description ?? 'N/A'}<br />
                <i className="mdi mdi-clock-outline" /> {formatTimestamp(selectedNotification?.$createdAt)}
              </p>
            </div>
          ) : (
            <SimpleBar style={{ height: "400px" }}>
              {sortedNotifications.map(notification => (
                <div key={notification.$id} className={`mb-2 "bg-light"`} style={{ padding: "1rem" }}>
                  <h6>{notification?.serviceProviderServices?.name ?? 'N/A'}</h6>
                  <p>{notification?.serviceProviderServices?.description ?? 'N/A'}<br />
                    {notification?.total ?? 'N/A'}
                    <br />{notification?.category_type ?? 'N/A'}</p>

                  <p className="text-muted">
                    <i className="mdi mdi-clock-outline" /> {notification.$createdAt
                      ? new Date(notification.$createdAt).toLocaleString("ar-EG", {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                      : 'Invalid date'}
                  </p>
                </div>
              ))}
            </SimpleBar>
          )}
        </ModalBody>
        <Button color="secondary" onClick={toggleModal}>إغلاق</Button>
      </Modal>
    </React.Fragment>
  )
}
const formatTimestamp = (timestamp) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Date(timestamp).toLocaleString(undefined, options);
};
NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NotificationDropdown)