import { Client, Databases, Account, Storage } from "appwrite"
import appwritPointes from "./appwritePointes"
const client = new Client()
export const account = new Account(client)
client
  .setEndpoint(appwritPointes.endpoint) // Your API Endpoint
  .setProject(appwritPointes.project) // Your project ID
//create a dtatbase instance
export const databases = new Databases(client)
export const storage = new Storage(client)


export default client
