// actionTypes.js

export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export const UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

export const UPDATE_COMMISSION_RATE = "UPDATE_COMMISSION_RATE";
export const UPDATE_ABOUT_US = "UPDATE_ABOUT_US";
export const CHANGE_MAINANCE_MOODE = "CHANGE_MAINANCE_MOODE";



export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";

// actions/types.js
export const CHANGE_ADMIN_ROLE_REQUEST = 'CHANGE_ADMIN_ROLE_REQUEST';
export const CHANGE_ADMIN_ROLE_SUCCESS = 'CHANGE_ADMIN_ROLE_SUCCESS';
export const CHANGE_ADMIN_ROLE_FAILURE = 'CHANGE_ADMIN_ROLE_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';


export const UPLOAD_BANNER_REQUEST = 'UPLOAD_BANNER_REQUEST';
export const UPLOAD_BANNER_SUCCESS = 'UPLOAD_BANNER_SUCCESS';
export const UPLOAD_BANNER_FAILURE = 'UPLOAD_BANNER_FAILURE';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_FAILURE = 'DELETE_DATA_FAILURE';



export const CREATE_SUPERADMIN_REQUEST = 'CREATE_SUPERADMIN_REQUEST';
export const CREATE_SUPERADMIN_SUCCESS = 'CREATE_SUPERADMIN_SUCCESS';
export const CREATE_SUPERADMIN_FAILURE = 'CREATE_SUPERADMIN_FAILURE';


export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';