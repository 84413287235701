import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Table } from "reactstrap";
import StatusModal from "./StatusModal";
import OrdersModal from "./OrdersModal";
import { updateOrderStatusRequest } from "../../store/orderManagement/actions";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ar } from 'date-fns/locale';

const filterOrderList = (orders, filterValue) => {
  if (filterValue === "") {
    return orders; // Return all orders if no filter selected
  }
  return orders.filter(order => order.booking_status === filterValue);
};

const OrderManagementDashboard = ({ orders }) => {
  const [orderList, setOrderList] = useState(orders);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [modal1, setModal1] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    let filteredOrders = filterOrderList(orders, filterValue);
    // Apply search filter
    if (searchQuery) {
      filteredOrders = filteredOrders.filter(order =>
        (order.app_user_id?.username?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (order.serviceName?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (order.app_user_id?.mobile?.includes(searchQuery))
      );
    }

    // Reverse the order of the filtered orders array
    const reversedOrders = [...filteredOrders].reverse();
    setOrderList(reversedOrders);
  }, [orders, filterValue, searchQuery]);

  const toggleViewStatusModal = (orderId, status) => {
    setSelectedOrderId(orderId);
    setSelectedStatus(status);
    setStatusModalOpen(!statusModalOpen);
  };

  const toggleViewModal = (orderData) => {
    setSelectedOrderData(orderData);
    setModal1(!modal1);
  };

  const toggleStatusModal = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  const updateOrderStatus = (orderId, newStatus) => {
    const updatedOrderList = orderList.map((order) =>
      order.$id === orderId ? { ...order, booking_status: newStatus } : order
    );
    setOrderList(updatedOrderList);
    dispatch(updateOrderStatusRequest(orderId, newStatus));
    toggleStatusModal();
  };

  return (
    <React.Fragment>
      <h2>لوحة إدارة الطلبات</h2>

      <div className="mb-4 mt-4">
        <label>البحث:</label>
        <input
          type="text"
          className="form-control"
          placeholder="ابحث عن الطلبات... مثل رقم الهاتف، الاسم، اسم الخدمة، إلخ"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      <div>
        <FilterOptions filterValue={filterValue} setFilterValue={setFilterValue} />
      </div>
      
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table>
              <tbody>
                {orderList.map(order => (
                  <tr key={order.$id}>
                    <td>{formatDistanceToNow(parseISO(order.$createdAt), { addSuffix: true, includeSeconds: true, locale: ar })}</td>
                    <td>{order.app_user_id?.username ?? 'N/A'}</td>
                    <td>{order.serviceProviderServices.name ?? 'N/A'}</td>
                    <td style={{ direction: "ltr" }}>{order.app_user_id?.mobile ?? 'N/A'}</td>
                    <td>
                      <strong>{translateStatus(order.booking_status)}</strong>
                    </td>
                    <td>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm"
                        onClick={() => toggleViewModal(order)}
                      >
                        عرض تفاصيل
                      </Button>
                    </td>
                    <td>
                      <Button
                        type="button"
                        color="success"
                        className="btn-sm"
                        onClick={() =>
                          toggleViewStatusModal(order.$id, order.booking_status)
                        }
                      >
                        تغير حالة الطلب
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <StatusModal
        isOpen={statusModalOpen}
        toggle={toggleViewStatusModal}
        orderId={selectedOrderId}
        status={selectedStatus}
        updateOrderStatus={updateOrderStatus}
      />
      <OrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        orderData={selectedOrderData}
      />
    </React.Fragment>
  );
};

OrderManagementDashboard.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
};

export default OrderManagementDashboard;

const translateStatus = status => {
  const translations = {
    pending: "قيد الانتظار",
    approved: "تم الموافقة عليه",
    rejected: "تم الرفض",
    completed: "تم الاكتمال",
    // Add more translations as needed
  };

  return translations[status] || status;
};

const FilterOptions = ({ filterValue, setFilterValue }) => {
  return (
    <div className="mb-4">
      <label>تصفية حسب الحالة:</label>
      <select
        className="form-control"
        id="filter"
        value={filterValue}
        onChange={e => setFilterValue(e.target.value)}
      >
        <option value="">الكل</option>
        <option value="pending">قيد الانتظار</option>
        <option value="approved">تم الموافقة</option>
        <option value="completed">تم الاكتمال</option>
      </select>
    </div>
  );
};