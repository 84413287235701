import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { confirmPayment } from '../../store/actions';


const PayModal = ({ isOpen, toggleModal, transaction }) => {
  const dispatch = useDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  if (!transaction || !transaction?.booking?.service_providers_id || !transaction?.serviceProviders) {
    return null;
  }
  const handleConfirm = () => {
    setIsConfirmationOpen(true);
  };

  const handleProceed = () => {
    const documentId = transaction.$id; // Assuming you have the documentId in your transaction object
    dispatch(confirmPayment(documentId));
    toggleModal();
  };

  const handleCancel = () => {
    setIsConfirmationOpen(false);
  };




  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>تفاصيل المستحق مقدم خدمات</ModalHeader>
      <ModalBody>
        {transaction && (
          <Table>
            <tbody>
              <tr>
                <th scope="row">تاريخ العملية</th>
                <td>{transaction.booking.$createdAt && format(parseISO(transaction.booking.$createdAt), 'MM dd, yyyy')}</td>
              </tr>
              <tr>
                <th scope="row">مقدم الخدمة</th>
                <td>{transaction.booking.service_providers_id.salon_name}</td>
              </tr>
              <tr>
                <th scope="row">الإجمالي</th>
                <td>{transaction.total} ريال</td>
              </tr>
              <tr>
                <th scope="row">نسبة عمولة</th>
                <td>{transaction.commission_rates}%</td>
              </tr>
              <tr>
                <th scope="row">الإجمالي بعد الخصم عمولة</th>
                <td>{transaction.total_after_discount} ريال</td>
              </tr>
              <tr>
                <th scope="row">اسم الحساب</th>
                <td>{transaction.booking.service_providers_id.salon_name}</td>
              </tr>
              <tr>
                <th scope="row">رقم الحساب</th>
                <td>{transaction.booking.service_providers_id.bank_account}</td>
              </tr>
              <tr>
                  <td >
                    <strong>   اسم الثلاثي :</strong>
                  </td>
                  <td style={{ direction: "ltr" }}> {transaction.serviceProviders.full_name}</td>
                </tr>
              <tr>
                  <td >
                    <strong>   رقم الهاتف مقدم الخدمة :</strong>
                  </td>
                  <td style={{ direction: "ltr" }}> {transaction.serviceProviders.mobile}</td>
                </tr>
            </tbody> 
             </Table>
        )}
      </ModalBody>
      <ModalFooter>
            <Button color="success" size="lg" onClick={handleConfirm}>
              تأكيد الدفع
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              إلغاء
            </Button>
      </ModalFooter>
      <Modal isOpen={isConfirmationOpen} toggle={handleCancel}>
        <ModalHeader>تأكيد الإجراء</ModalHeader>
        <ModalBody>
          <p>هل أنت متأكد أنك تريد المتابعة؟</p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleProceed}>
            نعم، متابعة
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            لا، إلغاء
          </Button>
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

export default PayModal;
