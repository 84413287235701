import { databases } from "helpers/appwrite/appwriteConfig";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_ORDERS_REQUEST,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  updateOrderStatusSuccess,
  UPDATE_ORDER_STATUS_REQUEST,
} from "./actions";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { ID } from "appwrite"
import axios from 'axios';


// Define a function to fetch orders from Appwrite (or your backend)
async function fetchOrdersFromAPI() {
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.ordersID
    );
    return response;
  } catch (error) {
    throw error;
  }
}

// Define a function to update order status
async function updateOrderStatusInAPI(orderId, newStatus) {
  try {
    const response = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.ordersID,
      orderId,
      { booking_status: newStatus }
    );

    return response;
  } catch (error) {
    throw error;
  }
}
function* fetchOrders() {
  try {
    // Make an API call to fetch orders using Appwrite
    const response = yield call(fetchOrdersFromAPI);
    // Dispatch success action with the fetched orders
    yield put(fetchOrdersSuccess(response.documents));
  } catch (error) {
    // Dispatch failure action if there's an error
    yield put(fetchOrdersFailure(error));
  }
}
function* updateOrderStatus({ payload }) {
  try {
    // Make an API request to update the order status
    const response = yield call(
      updateOrderStatusInAPI,
      payload.orderId,
      payload.newStatus
    );
    if (response) {
      const getType = (type) => {
        switch (type) {
          case "approved":
            return `تم المواقة على الطلب`;
          case "pending":
            return "قيد الانتظار";
          case "rejected":
            return "مرفوض";
          case "completed":
            return "مكتمل";
          default:
            return "/";
        }
      };

      const notificationDataForApp = {
        title: "تحديث حالة الحجز",
        content: `تم تحديث حالة الحجز ${response.serviceProviderServices.name} إلى ${getType(payload.newStatus)}`,
        notification_type: "BOOKING",
        orderId: response.$id,
        isSeen: false,
        appUser: response.app_user_id.$id,
        created_at: new Date().toISOString(),
      };
      try {
        yield call(appNotification, notificationDataForApp, response.app_user_id.notification_token);
      } catch (error) {
        throw error
      }

      

      const notificationData = {
        title: "تم تحديث حالة الطلب",
        content: `تم تحديث حالة الطلب ${response.serviceProviderServices.name} الخاص بك إلى ${getType(payload.newStatus)} من قبل الإدارة.`,
        notification_type: "BOOKING",
        orderId: response.$id,
        seen: false,
        serviceProviders: response.service_providers_id.$id,
        created_at: new Date().toISOString(),
      };

      // Retrieve the notification tokens array
      const notificationTokens = response.service_providers_id.notification_token || [];

      // Send notification to each token
      for (const token of notificationTokens) {
        try {
          yield call(createNotificationInAPI, notificationData, token);
        } catch (error) {
          throw error
        }
      }
    }
    // Dispatch success action with the updated data
    yield put(updateOrderStatusSuccess(response));
  } catch (error) {
    throw error;
  }
}




async function createNotificationInAPI(notificationData, deviceToken) {
  const title = notificationData.title;
  const body = notificationData.content;
  const API_URL = 'https://node-server-olive.vercel.app/send-notification';

  try {
    // Attempt to send the notification to the external API
    if (deviceToken) {
      try {
        await axios.post(API_URL, {
          deviceToken,
          title,
          body
        }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_NODE_API_KEY
          }
        });
      } catch (apiError) {
        console.error('Failed to send notification to external API:', apiError.message);
        // Optionally, log this error to your server or analytics platform
      }
    }

    // Create the notification document in Appwrite database
    const response = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.notificationID,
      ID.unique(),
      notificationData
    );

    return response;

  } catch (error) {
    // console.error('Error creating notification in Appwrite:', error.message);
    // Handle the error gracefully here, such as showing a user-friendly message or logging the error
    return null; // or return some meaningful fallback value
  }
}




async function appNotification(notificationData, deviceToken) {
  const API_URL = 'https://node-server-olive.vercel.app/send-notification';
  const title = notificationData.title;
  const body = notificationData.content;

  try {
    // Attempt to send the notification to the external API
    if (deviceToken) {
      try {
        await axios.post(API_URL, {
          deviceToken,
          title,
          body
        }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_NODE_API_KEY // API key for platform1
          }
        });
      } catch (apiError) {
        // console.error('Failed to send notification to external API:', apiError.message);
        // Optionally, handle the error (e.g., show a message, log it, etc.)
      }
    }

    // Attempt to create the notification document in Appwrite
    const response = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.appnotificationID,
      ID.unique(),
      notificationData
    );

    return response;
  } catch (error) {
    // console.error('Error creating notification in Appwrite:', error.message);
    // Handle the error gracefully
    return null; // or return some fallback value
  }
}




// Watch for FETCH_ORDERS_REQUEST action and trigger fetchOrders saga
function* watchFetchOrders() {
  yield takeLatest(FETCH_ORDERS_REQUEST, fetchOrders);
  yield takeLatest(UPDATE_ORDER_STATUS_REQUEST, updateOrderStatus);
}

export default watchFetchOrders;
