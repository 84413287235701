import { actionTypes } from "./actions";

export const transactions = (state = [], action) => {
  switch(action.type) {
    case actionTypes.SET_TRANSACTIONS:
      return action.data;
    default:
      return state;
  }
};

export default transactions;