import PropTypes from "prop-types"
import React from "react"
import { useState, useEffect } from "react"
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Link,  } from "react-router-dom"
import { useNavigate } from "react-router-dom";

//redux
import {  useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import Cookies from "js-cookie"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

// import images
// import profile from "assets/images/profile-img.png";
import logo from "assets/images/wlogo.png"
import CarouselPage from "pages/AuthenticationInner/CarouselPage"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  const history = useNavigate();

  const userId = Cookies.get("userId")
  const sessionId = Cookies.get("sessionId")
  //meta title
  document.title = "تسجيل الدخول | DBsaloni - Admin & Dashboard "
  const dispatch = useDispatch()
  useEffect(() => {
    // If the user is already logged in, redirect them to the dashboard or another authorized page
    if (userId && sessionId) {
      // Redirect to the dashboard or another authorized page
     
    return history("/dashboard")
    }
  }, [userId, sessionId])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("رجاءا أدخل  إلكتروني"),
      password: Yup.string().required("من فضلك أدخل رقمك السري"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const [toast2, setToast2] = useState(true)

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link
                        to="https://dbsaloni.com"
                        className="d-block auth-logo"
                      >
                        <img
                          src={logo}
                          alt=""
                          height="70"
                          className="logo-dark-element"
                        />
                        <img
                          src={logo}
                          alt=""
                          height="70"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">تسجيل الدخول</h5>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">
                              البريد الإلكتروني
                            </Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="أدخل بريد إلكتروني"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                !!(
                                  validation.touched.email &&
                                  validation.errors.email
                                )
                              } // Convert to boolean
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">كلمة المرور</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="أدخل كلمة المرور"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(
                                    validation.touched.password &&
                                    validation.errors.password
                                  )
                                } // Convert to boolean
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              دخول
                            </button>
                          </div>
                        </Form>
                   

                 
                      </div>
                    </div>

                    <div
                      className="mt-4 mt-md-5 text-center"
                      style={{ direction: "rtl" }}
                    >
                      <p className="mb-0">
                        © {new Date().getFullYear()} DBSaloni{" "}
                        <i className="mdi mdi-heart text-danger"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
