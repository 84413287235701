import React, { useState, useEffect } from 'react';
import { Col, Card, CardBody, CardTitle, Spinner, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import * as actions from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import AdminRow from './AdminRow'; // Assuming you've created a separate component

export default function Admins() {
    const { admins } = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchAdminsRequest());
    }, [dispatch]);


    return (
        <Col xl={9}>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">قائمة المستخدمين الإداريين</CardTitle>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>الاسم</th>
                                    <th>البريد الإلكتروني</th>
                                    <th>الدور</th>
                                    <th>تغيير الدور</th>
                                    {/* <th>حذف</th> */}
                                    <th>كلمة المرور</th>
                                </tr>
                            </thead>
                            <tbody>
                                {admins.map(admin => (
                                    <AdminRow key={admin.$id} admin={admin} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
}