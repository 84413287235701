import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
import orderSaga from "./orderManagement/saga"
import resolvedTsaga from "./dbInvoices/sagas"
import serviceSaga from "./servicesManagment/sagas"
import joinSaga from "./serviceProviders/saga"
import clientSaga from "./clientManagement/saga"
import settings from './generalSettings/saga'
export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(resolvedTsaga),
    fork(orderSaga),
    fork(serviceSaga),
    fork(joinSaga),
    fork(clientSaga),
    fork(settings),


    
  ])
}
