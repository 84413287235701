import { actionTypes } from "./actions";
import { call, put, takeEvery } from 'redux-saga/effects'
import { databases } from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { toast } from 'react-toastify';
import { ID } from "appwrite";
import axios from 'axios';


async function updateStatus(documentId) {

  const response = await databases.updateDocument(
    appwritPointes.databaseID,
    appwritPointes.invoicesID,
    documentId,
    { paid_status: 'paid' }
  );
  if (response) {
    return response
  } else {
    return false
  }
}

function* confirmPaymentSaga(action) {
  // Handle any additional logic before confirming the payment, if needed
  const { documentId } = action;
  const response = yield call(updateStatus, documentId)


  if (response) {
    toast.success('تم تأكيد الدفع بنجاح!');
    const notificationData = {
      title: `تأكيد دفع المستحق للحجز `,
      content: `تم دفع المستحق بنجاح للحجز بعنوان تعريفي ${response.booking.app_user_id.username}، الخدمة: ${response.booking.serviceProviderServices.name}، من قبل الإدارة `,      notification_type: "PAYMENT",
      orderId: response.booking.$id,
      seen: false,
      serviceProviders: response.serviceProviders.$id,
      created_at: new Date().toISOString(),
    };
    const notificationTokens = response.serviceProviders.notification_token || [];

    for (const token of notificationTokens) {
      try {
        yield call(createNotificationInAPI, notificationData, token);
      } catch (error) {
        throw error
      }
    }

  }

  const respon = yield call(fetchInvoices)
  // Dispatch an action to set the transactions or perform any other actions
  yield put({ type: actionTypes.SET_TRANSACTIONS, data: respon });
}


async function createNotificationInAPI(notificationData, deviceToken) {
  const API_URL = 'https://node-server-olive.vercel.app/send-notification';
  const title = notificationData.title;
  const body = notificationData.content;

  try {
    // Attempt to send the notification to the external API
    if (deviceToken) {
      try {
        await axios.post(API_URL, {
          deviceToken,
          title,
          body
        }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_NODE_API_KEY // API key for platform1
          }
        });
      } catch (apiError) {
        // console.error('Failed to send notification to external API:', apiError.message);
        // Optionally, handle the error (e.g., show a message, log it, etc.)
      }
    }

    // Attempt to create the document in Appwrite
    const response = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.notificationID,
      ID.unique(),
      notificationData
    );

    return response;
  } catch (error) {
    console.error('Error creating notification in Appwrite:', error.message);
    // Handle the error without stopping the app
    return null; // or return some fallback value
  }
}






async function fetchInvoices() {

  const re = await databases.listDocuments(
    appwritPointes.databaseID,
    appwritPointes.invoicesID,
  )
  return re.documents
}

function* fetchTransactionsSaga() {
  const respon = yield call(fetchInvoices)
  yield put({ type: actionTypes.SET_TRANSACTIONS, data: respon });
}



export default function* rootSaga() {
  yield takeEvery(actionTypes.FETCH_TRANSACTIONS, fetchTransactionsSaga);
  yield takeEvery(actionTypes.CONFIRM_PAYMENT, confirmPaymentSaga);
}


