// actions.js
import * as actionTypes from "./actionTypes"

export const addService = newService => ({
  type: actionTypes.ADD_SERVICE,
  payload: newService,
})

export const updateService = updatedService => ({
  type: actionTypes.UPDATE_SERVICE,
  payload: updatedService,
})
export const addServiceSuccess = Newservice => {
  return {
    type: actionTypes.ADD_SERVICE_SUCCESS,
    payload: Newservice,
  }
}
export const removeService = serviceId => ({
  type: actionTypes.REMOVE_SERVICE,
  payload: serviceId,
})

export const removeServiceSuccess = serviceId => {
  return {
    type: actionTypes.REMOVE_SERVICE_SUCCESS,
    payload: serviceId,
  }
}
export const setSelectedService = service => ({
  type: actionTypes.SET_SELECTED_SERVICE,
  payload: service,
})

export const fetchServicesRequest = () => ({
  type: actionTypes.FETCH_SERVICES_REQUEST,
})

export const fetchServicesSuccess = services => ({
  type: actionTypes.FETCH_SERVICES_SUCCESS,
  services,
})

export const fetchServicesFailure = error => ({
  type: actionTypes.FETCH_SERVICES_FAILURE,
  error,
})

export const fetchCategoriesRequest = () => ({
  type: actionTypes.FETCH_CATEGORIES_REQUEST,
})

export const fetchCategoriesSuccess = categories => ({
  type: actionTypes.FETCH_CATEGORIES_SUCCESS,
  payload: categories,
})

export const fetchCategoriesFailure = error => ({
  type: actionTypes.FETCH_CATEGORIES_FAILURE,
  payload: error,
})

export const fetchTypesRequest = () => ({
  type: actionTypes.FETCH_TYPES_REQUEST,
})

export const fetchTypesSuccess = types => ({
  type: actionTypes.FETCH_TYPES_SUCCESS,
  payload: types,
})


export const addCategory = category => ({
  type: actionTypes.ADD_CATEGORY,
  payload: category,
})
export const removeCategory = categoryId => ({
  type: actionTypes.REMOVE_CATEGORY,
  payload: categoryId,
})