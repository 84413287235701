import { put, takeLatest, call } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import appwritPointes from "helpers/appwrite/appwritePointes"
import { databases } from "helpers/appwrite/appwriteConfig"

// Simulated API functions (replace with actual API calls)
async function   fetchClientsApi (){
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID, // db ID
      appwritPointes.appUsers //  collection ID
    )
    return response.documents
  } catch (error) {
    throw error
  }
};

async function updateClientStatusApi(clientId, newStatus) {
  try {
    const response = await databases.updateDocument(
      appwritPointes.databaseID, // db ID
      appwritPointes.appUsers,
      clientId,
      {
        account_status: newStatus,
      } //  collection ID
    )
    return response
  } catch (error) {
    throw error
  }

};

// Worker Saga for fetching clients
function* fetchClients() {
  try {
    const clients = yield call(fetchClientsApi);
    yield put({ type: actionTypes.FETCH_CLIENTS_SUCCESS, payload: clients });
  } catch (error) {

    yield put({ type: actionTypes.FETCH_CLIENTS_FAILURE, payload: error });
  }
}

// Worker Saga for updating client status
function* updateClientStatus(action) {
  const { clientId, newStatus } = action.payload;
  try {
    yield call(updateClientStatusApi, clientId, newStatus);
    const clients = yield call(fetchClientsApi);
    yield put({ type: actionTypes.FETCH_CLIENTS_SUCCESS, payload: clients });
  } catch (error) {
    yield put(actions.updateClientStatusFailure(error));
  }
}
export function* clientSaga() {
    yield takeLatest(actionTypes.FETCH_CLIENTS_REQUEST, fetchClients);
    yield takeLatest(actionTypes.UPDATE_CLIENT_STATUS_REQUEST, updateClientStatus);
  }
  
  export default clientSaga;