import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Form, Input, Button, CardBody } from "reactstrap";
import * as actions from '../../store/actions';

export default function AboutUsSettings({ about }) {
    const { about_us, $id } = about;
    const [aboutUsText, setAboutUsText] = useState(""); // Initialize with an empty string
    const [editedText, setEditedText] = useState(""); // Initialize with an empty string
    const dispatch = useDispatch();

    useEffect(() => {
        // Set the initial state with the about_us value when the component mounts
        setAboutUsText(about_us);
        setEditedText(about_us);
    }, [about_us]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Update the About Us text with the edited text
        setAboutUsText(editedText);
        // You can also send the edited text to your server or store it in your database here.
        dispatch(actions.updateAboutUs({ editedText, $id }));
        // Optionally, show a success message or perform other actions.
    };

    return (
        <Col xl={9}>
            <p>تحرير نص عنا</p>
            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <Input
                                type="textarea"
                                value={editedText}
                                onChange={(e) => setEditedText(e.target.value)}
                                rows="4"
                            />
                        </div>

                        <Button type="submit" color="primary">
                            حفظ
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
}
