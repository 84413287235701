import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

const CarouselPage = () => {
  const styles = {

    color: "white",
    backgroundColor: "#60646f",
  }
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4" style={styles}>
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3" >
                        <span className="text-primary" >SUPER</span> {" "}
                        ADMIN
                      </h4>
                      <div dir="ltr">
                        <Carousel className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel" 
                        showThumbs={false}>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                &ldquo;
                                القدوم معاً هو البداية؛ البقاء معاً هو التقدم. العمل معاً هو النجاح
                                &ldquo;
                                </p>

                                <div>
                                  {/* <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;أفضل توقيت لزرع شجرة كان قبل 20 سنة و أفضل ثاني توقيت هو الآن. &ldquo;
                                </p>

                                <div>
                                  {/* <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
