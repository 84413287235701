import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as providerActions from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import ProviderModal from "./ProviderModal";
import EditFormModal from "./EditFormModal";
import TableContainer from "components/Common/TableContainer";
import { Pdate } from "./AccCol";
import { toast } from "react-toastify";

const ProvidersList = () => {
  const { serviceProviders } = useSelector((state) => state.serviceProviders);
  const dispatch = useDispatch();
  const [selectedProviderData, setSelectedProviderData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  useEffect(() => {
    dispatch(providerActions.fetchServiceProvidersRequest());
  }, [dispatch]);

  const handleEditProviderModal = (service) => {
    setEditData(service);
    setModal2(true);
  };

  const handleRemoveProvider = (id) => {
    // Show a custom confirmation toast
    toast(
      <div>
        <p>سيتم حذف مزود الخدمة هذا، هل أنت متأكد أنك تريد المتابعة؟</p>
        <Button
          className="btn-primary m-3"
          onClick={() => {
            dispatch(providerActions.removeServiceProviderRequest(id));
            toast.dismiss();
          }}
        >
          مواصلة
        </Button>
        <Button
          className="btn-primary"
          onClick={() => {
            toast.dismiss();
          }}
        >
          الغاء
        </Button>
      </div>
    );
  };

  const toggleViewModal = (providerData) => {
    setSelectedProviderData(providerData);
    setModal1(!modal1);
  };

  const columns = useMemo(
    () => [
      {
        accessor: "email",
        disableFilters: false,
        filterable: true,
        Cell: (cellProps) => <Pdate {...cellProps} />,
      },
      {
        Header: "تحرير الحساب",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            className="btn-primary"
            onClick={() => handleEditProviderModal(row.original)}
          >
            تحرير الحساب
          </Button>
        ),
      },
      {
        Header: "حذف الحساب",
        accessor: "reject",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            className="btn-danger"
            onClick={() => handleRemoveProvider(row.original.$id)}
          >
            حذف
          </Button>
        ),
      },
      {
        Header: "حالة الحساب",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button className={`${getStatusButtonClass(row.original.account_status)}`}>
            {translateStatus(row.original.account_status)}
          </Button>
        ),
      },
      {
        accessor: "description",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button onClick={() => toggleViewModal(row.original)}>عرض التفاصيل</Button>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <TableContainer
        columns={columns}
        data={serviceProviders || []}
        isGlobalFilter={false}
        customPageSize={5}
        customPageSizeOptions={true}
      />
      <ProviderModal
        isOpen={modal1}
        toggle={toggleViewModal}
        providerData={selectedProviderData}
      />
      <EditFormModal isOpen={modal2} toggle={() => setModal2(false)} editData={editData || {}} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ProvidersList: state.serviceProviders,
});
export default connect(mapStateToProps, providerActions)(ProvidersList);

const getStatusButtonClass = (accountStatus) => {
  switch (accountStatus) {
    case "active":
      return "btn-success";
    case "paused_for_subscription_expiry":
      return "btn-danger";
    case "action_required":
      return "btn-warning";
    default:
      return "btn-secondary";
  }
};

const translateStatus = (status) => {
  const translations = {
    active: "نشط",
    paused_for_subscription_expiry: "لانتهاء صلاحية الاشتراك",
    action_required: "غير نشط",
  };
  return translations[status] || status;
};
