import React from 'react';
import { Container } from "reactstrap";
//components
import LatestOrders from './LatestOrders';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import OrderSubscription from './OrderSubscription';
    const newPage = () => {
        //meta title
        document.title="ادارة الطلبات| DBsaloni Admin & Dashboard ";
        return (
            <>
                <div className="page-content">
                    <Container fluid={false}>
                        <OrderSubscription />
                        <Breadcrumbs title=" طلبات الواردة " breadcrumbItem=" الطلبات" />
                       <LatestOrders title={" لوحة إدارة الطلبات" }/>
                    </Container>
                </div>
            </>
        );
    }

export default newPage;