import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactions } from "../../store/dbInvoices/actions";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { parseISO, format } from 'date-fns';
import TransactionModal from "./TransactionModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ResolvedTransferTransactions = () => {
  const transactions = useSelector((state) => state.transactions);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleDateRangeModal = () => {
    setIsDateRangeModalOpen(!isDateRangeModalOpen);
  };

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
    toggleModal();
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const applyDateFilter = () => {
    toggleDateRangeModal();
  };

  const filteredTransactions = transactions.filter((transaction) => {
    if (startDate && endDate) {
      const transactionDate = parseISO(transaction.$createdAt);
      return transactionDate >= startDate && transactionDate <= endDate;
    }
    return true;
  });

  const paidInvoices = filteredTransactions.filter((transaction) => transaction.paid_status === 'paid');

  const columns = useMemo(
    () => [
      {
        Header: "تاريخ العملية",
        accessor: "$createdAt",
        Cell: (cellProps) => {
          return <span>{format(parseISO(cellProps.value), 'MM dd, yyyy')}</span>;
        },
      },
      {
        Header: " مقدم الخدمة",
        accessor: "customerName",
        Cell: ({ row }) => row.original.serviceProviders && row.original.serviceProviders.salon_name,
      },
      {
        Header: " عرض التفاصيل",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            type="button"
            style={{ width: "100%" }}
            onClick={() => handleRowClick(row.original)}
            className={"btn btn-primary  text-center"}
          >
            عرض
          </Button>
        ),
      },
      {
        Header: "حالة الدفع",
        accessor: "paid_status",
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: (<Button
          className={"btn btn-primary  text-center"}
          onClick={toggleDateRangeModal}>
          حدد نطاق التاريخ
        </Button>),
        accessor: "button",
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <h2> حوالات المكتملة </h2>

            <TableContainer
              columns={columns}
              data={paidInvoices}
              customPageSize={10}
              className="custom-header-css"
            />
            <TransactionModal
              isOpen={isModalOpen}
              toggleModal={toggleModal}
              transaction={selectedTransaction}
            />
            <Modal isOpen={isDateRangeModalOpen} toggle={toggleDateRangeModal}>
              <ModalHeader toggle={toggleDateRangeModal}>حدد نطاق التاريخ</ModalHeader>
              <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={applyDateFilter}>
                  تطبيق
                </Button>
                <Button color="secondary" onClick={toggleDateRangeModal}>
                  إلغاء
                </Button>
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ResolvedTransferTransactions;

const Status = (cell) => {
  const getStatusBadge = (status) => {
    switch (status) {
      case "paid":
        return "btn btn-success  btn-label text-center";
      case "pending":
        return "btn btn-warning  btn-label";
      case "failed":
        return "btn btn-danger  btn-label";
      default:
        return "secondary";
    }
  };
  return (
    <Button
      type="button"
      style={{ width: "100%" }}
      className={`${getStatusBadge(cell.value)}`}
    >
      <i
        className={
          cell.value === "paid"
            ? "bx bx-check-double label-icon"
            : "" || cell.value === "pending"
              ? "bx bx-error label-icon"
              : "" || cell.value === "failed"
                ? "bx bx-block label-icon"
                : "" || cell.value === ""
                  ? ""
                  : ""
        }
      ></i>{" "}
      {translateStatus(cell.value)}
    </Button>
  );
};

const translateStatus = (status) => {
  const translations = {
    pending: "قيد الانتظار",
    paid: "تم",
    failed: "فشل",
  };

  return translations[status] || status;
};
