import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
//Order managements
import orders from "./orderManagement/reducer"
//Dbinvoices 
import transactions from './dbInvoices/reducer'
//acount Management 
import service from './servicesManagment/reducer'
//mails
import mails from "./mails/reducer";
//Dashboard 
import Dashboard from "./dashboard/reducer";

import serviceProviders from "./serviceProviders/reducer"
import clients from './clientManagement/reducer'
import settings from './generalSettings/reducer'


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  mails,
  Dashboard,
  service,
  transactions,
  orders,
  serviceProviders,
  clients,
  settings
});

export default rootReducer;
