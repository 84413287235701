import React, { useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import * as JoinActions from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { Pdate, Ddate, Name } from "./AccCol"
import { toast } from "react-toastify"

const joinRequest = () => {
  const { serviceProviderRequests, error } = useSelector(
    state => state.serviceProviders
  )
  const dispatch = useDispatch()
  const [selectedProviderData, setSelectedProviderData] = useState(null)
  const [modal1, setModal1] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [discription, setDiscription] = useState(null)

  useEffect(() => {
    dispatch(JoinActions.fetchServiceProviderRequestsRequest())
  }, [])
  if (error) {
    toast.error('مزود الخدمة بالبريد الإلكتروني المطلوب موجود بالفعل');
    dispatch(JoinActions.clearError())
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const moreDetails = dis => {

    setDiscription(dis)
    toggleModal()
  }

  const toggleViewModal = providerData => {
    setSelectedProviderData(providerData)
    setModal1(!modal1)
  }

  const approved = data => {
    dispatch(JoinActions.approveServiceProviderRequest(data))
  }

  const rejected = data => {
    const ids = {
      $collectionId: data.$collectionId,
      $id: data.$id
    }
    dispatch(JoinActions.rejectServiceProviderRequest(ids))
  }

  const columns = useMemo(
    () => [
      {
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button onClick={() => approved(row.original)}>قبول</Button>
        ),
      },
      {
        accessor: "reject",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button className="btn-danger" onClick={() => rejected(row.original)}>
            رفض
          </Button>
        ),
      },

      {
        Header: "اسم العميل",
        accessor: "full_name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />,
      },
      {
        accessor: "email",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => <Pdate {...cellProps} />,
      },
      {
        Header: "الهاتف",
        accessor: "mobile",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Ddate {...cellProps} />,
      },

      {

        accessor: "description",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button onClick={() => moreDetails(row.original.description)}>
            عرض التفاصيل
          </Button>
        ),
      },
    ],
    []
  )

  return (
    <div>

      <TableContainer
        columns={columns}
        data={serviceProviderRequests || []}
        isGlobalFilter={false}
        customPageSize={5}
        customPageSizeOptions={true}
      />
      <DiscriptionModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        discription={discription}
      />
    </div>
  )
}
const mapStateToProps = state => ({
  ProvidersList: state.serviceProviders,
})

const DiscriptionModal = ({ isOpen, toggleModal, discription }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>تفاصيل العملية</ModalHeader>
      <ModalBody>
        {discription && (
          <>
            <p> وصف: {discription}</p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleModal}>
          إغلاق
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default connect(mapStateToProps, JoinActions)(joinRequest)
