import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,

} from "reactstrap"
import OrderStatusChart from './OrderStatusChart';
//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import { CardTitle } from "reactstrap"
import * as actions from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import ActivityComp from "./ActivityComp"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import RequestPermissionComponent from "RequestPermissionComponent";
const Dashboard = props => {
  const dispatch = useDispatch()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))


  const { services, totalServices, totalCategories } = useSelector(
    state => state.service
  )
  const { totalCompletedOrders, totalPendingOrders, totalRejectedOrders, orders } = useSelector(state => state.orders)
  const { serviceProviderRequests, serviceProviders } = useSelector(state => state.serviceProviders)
  const transactions = useSelector(state => state.transactions)
  const { clients } = useSelector(state => state.clients)
  const totalCompletedAmount = orders.reduce((total, order) => {
    if (order.booking_status === 'completed') {
      return total + order.total;
    }
    return total;
  }, 0);

  const invoices = [];

  // Assuming transactions is an array of objects with a 'booking' property containing the payment status
  const paidInvoices = transactions.filter((transaction) => transaction.paid_status === 'pending');

  // You can append the extracted transactions to the invoices array
  invoices.push(...paidInvoices);

  // Sum the total amount of all paid invoices
  const totalAmount = paidInvoices.reduce((acc, invoice) => acc + invoice.total, 0);

  const orderStatusData = [totalCompletedOrders, totalPendingOrders, totalRejectedOrders]; // The percentages for Completed, Pending, and Failed orders

  useEffect(() => {
    dispatch(actions.fetchServicesRequest());
    dispatch(actions.fetchOrdersRequest());
    dispatch(actions.fetchServiceProviderRequestsRequest());
    dispatch(actions.fetchClientsRequest());
    dispatch(actions.fetchTransactions());
    dispatch(actions.fetchServiceProvidersRequest());
    dispatch(actions.fetchCategoriesRequest())

  }, [dispatch]);




  useEffect(() => {
    setReports(prevReports => [
      { title: " إجمالي قيمة المستحقات", iconClass: "bx-copy-alt", description: ` ${totalAmount} ريال`, },
      { title: " إجمالي عدد طلبات التسجيل", iconClass: "bx-archive-in", description: serviceProviderRequests.length, },
      { title: " إجمالي عدد العملاء", iconClass: "bx-purchase-tag-alt", description: clients.length, },
      { title: " إجمالي عدد الخدمات  ", iconClass: "bx-copy-alt", description: services.length, },
      { title: "إجمالي قيمة الطلبات", iconClass: "bx-archive-in", description: ` ${totalCompletedAmount} ريال`, },
      { title: " إجمالي عدد مقدمي الخدمات", iconClass: "bx-purchase-tag-alt", description: serviceProviders.length, },
    ])

  }, [serviceProviderRequests, clients, services, orders, serviceProviders])

  useEffect(() => {

  }, [dispatch])

  const [reports, setReports] = useState([
    { title: " إجمالي قيمة المستحقات", iconClass: "bx-copy-alt", description: 3, },
    { title: " إجمالي عدد طلبات التسجيل", iconClass: "bx-archive-in", description: serviceProviderRequests.length, },
    { title: " إجمالي عدد العملاء", iconClass: "bx-purchase-tag-alt", description: clients.length, },
    { title: " إجمالي عدد الخدمات  ", iconClass: "bx-copy-alt", description: services.length, },
    { title: "إجمالي قيمة الطلبات", iconClass: "bx-archive-in", description: `SAR ${totalCompletedAmount}`, },
    { title: " إجمالي عدد مقدمي الخدمات", iconClass: "bx-purchase-tag-alt", description: serviceProviders.length, },
  ])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "الصفحة الرئيسية | DBsaloni"

  return (
    <React.Fragment>
      <div className="page-content">
      <RequestPermissionComponent />
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("الصفحة الرئيسية")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />

              <ActivityComp services={services} />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>

                <CardBody>
                  <CardTitle className="mb-4">
                    الرسوم البيانية معدل الطلبات
                  </CardTitle>
                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalCompletedOrders}</h5>
                        <p className="text-truncate">
                          الطلبات المكتملة
                        </p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalPendingOrders}</h5>
                        <p className="text-truncate">قيد المراجعة</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalRejectedOrders}</h5>
                        <p className="text-truncate">
                          الطلبات الملغاة
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <OrderStatusChart data={orderStatusData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
